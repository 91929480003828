.resume {
    .page-title {
        margin-bottom: 20px;

        @include lg {
            margin-bottom: 15px;
        }
    }

    &-date {
        font-size: 16px;
        line-height: 21px;

        @include lg {
            margin-bottom: 15px;

        }

        span {
            font-weight: bold;
        }
    }

    &-rubrics {
        font-size: 16px;
        line-height: 21px;
        display: flex;
        margin-bottom: 25px;

        @include md {
            display: block;
        }

        @include lg {
            margin-bottom: 55px;
        }

        &-title {
            margin-right: 5px;

            @include md {

            }
        }

        &-links {
            display: flex;
            flex-direction: column;

            @include md {
                display: inline;
            }


            a {
                text-decoration: underline;

                &::after {
                    content: ";";
                }

                &:last-child {
                    margin-right: 0;

                    &::after {
                        display: none;
                    }
                }
                
                @include md {
                    margin-right: 8px;
                }
                
                @include hover-focus-active {
                    text-decoration: none;
                }
            }

        }
    }

    &-person {
        > .resume-person-name {
            @include xl {
                display: none;
            }
        }

        &-name {
            font-weight: bold;
            font-size: 25px;
            line-height: 28px;
            margin-bottom: 27px;
            text-align: left;

            @include lg {
                font-size: 35px;
                line-height: 45px;
            }
        }

        &-main {
            @include lg {
                display: flex;
                margin-bottom: 30px;
            }

            &-top {
                align-items: center;
                display: flex;

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }

                @include lg {
                    flex-shrink: 0;
                }

                @include xl {
                    margin-right: 70px;
                }

                .resume-person-position {
                    @include lg {
                        display: none;
                    }
                }

                .resume-person-salary {
                    @include lg {
                        display: none;
                    }
                }
            }

            &-details {
                .resume-person-name {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                .resume-person-position {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }

                .resume-person-salary {
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }

        &-position {
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;

            @include lg {
                font-size: 25px;
                line-height: 32px;
                margin-bottom: 10px;
            }
        }

        &-image {
            height: 82px;
            width: 82px;
            border-radius: 50%;
            margin-right: 30px;

            @include md {
                height: 130px;
                width: 130px;
            }

            @include lg {
                height: 180px;
                width: 180px;
            }

            @include xl {
                height: 246px;
                width: 246px;
            }
        }

        &-info {
            font-size: 20px;
            line-height: 29px;
            margin-bottom: 25px;

            @include lg {
                margin-bottom: 0;
            }

            &-block {
                &:first-child {
                    @include xl {
                        margin-right: 70px;
                    }
                }
            }

            &-item {
                margin-bottom: 2px;

                span {
                    font-weight: bold;
                }

                a {
                    color: $c-default;
                    font-weight: bold;
                }
            }
        }

        &-salary {
            font-size: 20px;
            line-height: 26px;

            @include lg {
                font-size: 25px;
                line-height: 32px;
                margin-bottom: 10px;
            }
        }
    }

    &-info {
        &-block {
            margin-bottom: 20px;

            @include md {
                margin-bottom: 45px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &-title {
                font-weight: bold;
                font-size: 20px;
                line-height: 26px;
                margin-bottom: 10px;

                @include md {
                    font-size: 25px;
                    line-height: 32px;
                    margin-bottom: 25px;
                }
            }

            &-text {
                font-size: 16px;
                line-height: 1.44;
                margin-bottom: 40px;

                @include md {
                    font-size: 20px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: $c-default;
                }
            }
        }
    }

    &-add {
        background: $c-lgrey;
        border-radius: 5px;
        text-align: center;
        padding: 32px 0;

        @include lg {
            padding: 48px 0;
        }
    }
}