.footer {
    border-top: 1px solid $c-accent;
    margin-top: auto;

    &-main {
        padding: 22px 0 46px;

        @include md {
            padding-top: 50px;
        }

        @include lg {
            padding: 100px 0 60px;
        }

        &-logo {
            margin-bottom: 30px;
            text-align: center;
            
            @include lg {
                text-align: left;
            }

            img {
                max-width: 97px;
            }
        }

        &-profit {
            text-align: center;
            
            @include lg {
                text-align: left;
            }

            a {
                display: inline-block;
            }

            &-link {
                align-items: center;
                color: $c-accent;
                display: flex;
                font-size: 18px;
                justify-content: center;
                margin-top: 5px;

                @include lg {
                    justify-content: flex-start;
                }
            }
        }
    }

    &-nav {
        margin-bottom: 15px;

        &-menu {
            list-style: none;
            padding-left: 0;

            &-item {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 3px;

                &:last-child {
                    margin-bottom: 0;
                }

                @include lg {
                    font-size: 18px;
                }

                a {
                    color: $c-default;

                    @include hover-focus-active {
                        color: $c-accent;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-rules {
        @include media-breakpoint-down(md) {
            display: none;
        }

        font-size: 14px;
        line-height: 21px;
        max-width: 350px;
    }

    &-form {
        margin-bottom: 45px;
        margin-top: 60px;
        text-align: center;

        @include lg {
            margin-top: 0;
        }

        &-title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
        }

        input {
            border-radius: 20px;
            color: $c-grey;
            display: block;
            font-size: 14px;
            margin: 0 auto 18px;
            max-width: 100%;
            padding: 0 5px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            width: 225px;
        }

        button {
            font-size: 14px;
            height: 35px;
            line-height: 35px;
            max-width: 100%;
            padding: 0 5px;
            width: 225px;
        }
    }

    &-bottom {
        border-top: 1px solid $c-accent;
        font-size: 14px;
        padding: 20px 10px;
        text-align: center;
    }
}