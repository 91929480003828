.feedbacks {
    
    .page-title {

        @include lg {
            margin-bottom: 100px;
            text-align: center;
        }
    }

    &-item {
        margin-bottom: 65px;
        padding: 0 10px;
        text-align: center;

        @include lg {
            margin-bottom: 95px;
            padding: 0 20px;

        }

        @include xl {
            padding: 0 40px;
        }

        &-avatar {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            display: block;
            margin: 0 auto 16px;
        }

        &-name {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 8px;

            @include md {
                font-size: 20px;
                line-height: 1;
                margin-bottom: 5px;
            }
        }

        &-club {
            font-size: 14px;
            margin-bottom: 10px;

            @include md {
                font-size: 20px;
                font-weight: bold;
                line-height: 1;
                margin-bottom: 15px;
            }
        }

        .social {
            justify-content: center;
            margin-bottom: 10px;

            @include md {
                margin-bottom: 30px;
            }

            &-item {
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-description {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 0;
            opacity: .7;

            @include md {
                font-size: 18px;
            }
        }
    }

    &-more {
        display: block;
        margin: 0 auto;
        padding: 0 44px;
    }
}