.slick {
    /* Slider Default styles*/
    &-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        user-select: none;

        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    &-list {
        position: relative;

        display: block;
        overflow: hidden;

        margin: 0;
        padding: 0;

        transform: translate3d(0, 0, 0);

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    &-track {
        position: relative;
        top: 0;
        left: 0;

        display: block;
        margin-left: auto;
        margin-right: auto;

        transform: translate3d(0, 0, 0);

        &:before,
        &:after {
            display: table;
            content: '';
        }

        &:after {
            clear: both;
        }
    }

    &-loading .slick-track {
        visibility: hidden;
    }

    &-slide {
        display: none;
        float: left;

        height: 100%;
        min-height: 1px;

        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }

    }

    [dir='rtl'] .slick-slide {
        float: right;
    }

    &-initialized .slick-slide {
        display: block;
    }
    &-loading .slick-slide {
        visibility: hidden;
    }

    &-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }

    &-arrow.slick-hidden {
        display: none;
    }

    //Custom styles
    &-dots {
        bottom: 20px;
        font-size: 0;
        left: 50%;
        line-height: 0;
        list-style: none;
        margin: 0 auto;
        padding-left: 0;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        width: 100%;

        @include md {
            bottom: 36px;
        }

        li {
            display: inline-block;
            margin-right: 4px;
            padding: 4px;

            @include md {
                padding: 5px;
            }

            &.slick-active {
                button {
                    background: $c-accent;
                }
            }

            button {
                background: $c-lgrey-3;
                border-radius: 2px;
                border: 0;
                height: 5px;
                transition: all $time ease-in-out;
                width: 30px;
            }
        }
    }

    &-arrow {
        background-color: $c-none;
        background-image: url(../img/svg/slider-arrow.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        border: 0;
        box-sizing: border-box;
        font-size: 0;
        height: 40px;
        line-height: 0;
        padding: 5px;
        position: absolute;
        top: 5px;
        transition: all $time;
        width: 20px;
        z-index: 1;

        &:hover,
        &:focus,
        &:active {
            opacity: .7;
        }
    }

    &-prev {
        left: 0;
        transform: rotate(180deg);
    }

    &-next {
        right: 0;
    }
}