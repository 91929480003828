//@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&display=swap&subset=cyrillic');

@font-face {
    font-family: 'PT Sans Narrow';
    src: url('../fonts/PTSansNarrow/PTSansNarrow-Regular.ttf');
    src: local('../fonts/PTSansNarrow/PTSansNarrow-Regular.ttf'),
    url('../fonts/PTSansNarrow/PTSansNarrow-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PT Sans Narrow';
    src: url('../fonts/PTSansNarrow/PTSansNarrow-Bold.ttf');
    src: local('../fonts/PTSansNarrow/PTSansNarrow-Bold.ttf'),
    url('../fonts/PTSansNarrow/PTSansNarrow-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.eot');
    src: local('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman'),
    url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff') format('woff'),
    url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.eot');
    src: local('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold'),
    url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff') format('woff'),
    url('../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}