.work {
    .page-title {
        margin-bottom: 20px;

        @include lg {
            margin-bottom: 35px;
        }
    }

    .page-subtitle {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 25px;

        @include lg {
            font-size: 18px;
            line-height: 29px;
            margin-bottom: 85px;
        }
    }

    &-option {
        border-bottom: 1px solid $c-lgrey-4;
        border-top: 1px solid $c-lgrey-4;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }

        @include sm {
            border: none;
        }

        &-container {
            height: 100%;
            display: flex;
            flex-direction: column;


            padding-bottom: 27px;
            padding-top: 20px;

            @include sm {
                border: 1px solid $c-lgrey-4;
                border-radius: 5px;
                padding: 20px 30px 27px;
            }

            @include lg {
                //display: flex;
                //flex-direction: column;
            }

            @include xl {
                padding: 35px 42px 45px 48px;
            }
        }

        &-title {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 10px;
            text-align: left;

            @include lg {
                font-size: 30px;
                margin-bottom: 15px;
            }
        }

        &-description {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 30px;

            @include lg {
                margin-bottom: 45px;
            }
        }

        &-links {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 22px;

            .btn {
                padding: 0 10px;
                width: calc(50% - 5px);
                justify-content: center;

                @include sm {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    width: calc(50% - 13px);
                }

                i {
                    display: none;

                    @include sm {
                        display: block;
                        margin-right: 10px;
                    }

                    @include lg {
                        display: none;
                    }

                    @include xl {
                        display: block;
                    }
                }
            }
        }

        &-actual {
            border-top: 1px solid $c-accent;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-top: 25px;

            &-title {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 20px;
            }

            &-row {
                align-items: center;
                display: flex;
                font-size: 14px;
                margin-bottom: 10px;

                @include md {
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 40px;
                    }
                }
            }

            &-date {


                @include lg {
                    font-size: 16px;
                }
            }

            &-position {
                color: $c-default;
            }

            &-salary {

                @include lg {
                    font-size: 16px;
                }
            }

            .btn {
                display: block;
                margin: 15px auto 0;
                max-width: 170px;

                @include md {
                    margin-top: auto;
                }
            }
        }
    }
}