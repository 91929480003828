.__typography {
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $c-default;
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        margin-bottom: 20px;
        margin-top: 30px;
        text-align: left;

        @include lg {
            font-size: 35px;
            line-height: 1.2;
        }

        &:nth-of-type(n + 2) {
            @include lg {
                margin-top: 65px;
            }
        }
    }
    
    a {
        text-decoration: underline;
        
        @include hover-focus-active {
            text-decoration: none;
        }
    }

    p,
    strong {
        //opacity: .8;
        font-family: $typography-font;
        font-size: 14px;
        line-height: 1.55;

        @include md {
            font-size: 16px;
            line-height: 1.7;
        }
    }

    p {
        margin-bottom: 15px;
    }

    b, strong {
        font-family: $typography-font;
        font-weight: bold;
    }

    strong {
        display: inline-block;
        //margin-top: 15px;
    }

    img {
        height: auto;
        margin: 30px auto 40px;



        +h2,
        +h3,
        +h4,
        +h5,
        +h6 {
            margin-top: 0;
        }
    }

    > img:first-child {
        margin-top: 0;
    }

    ul {
        font-family: $typography-font;
        list-style: none;
        margin-bottom: 20px;
        padding-left: 0;

        li {
            //opacity: .8;
            font-size: 14px;
            line-height: 20px;
            position: relative;
            padding-left: 15px;
            margin-bottom: 10px;

            @include md {
                font-size: 16px;
                line-height: 1.45;
            }

            &::before {
                content: "";
                display: block;
                height: 7px;
                width: 7px;
                background: $c-accent;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 7px;
            }
        }
    }

    .table-container {
        overflow-x: auto;
    }

    table {
        font-family: $typography-font;
        width: 100%;
        max-width: 100%;
        margin: 50px 0;
        background-color: $c-light;
        border: 1px solid $c-lgrey-4;
        //table-layout: fixed;

        th,
        td {
            padding: 15px 9px;
            vertical-align: top;
            border: 1px solid $c-lgrey-4;
            font-size: 14px;
            line-height: 1.2;

            p,
            strong {
                line-height: 1.3;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        td {
            @include lg {
                font-size: 16px;
            }
        }

        thead th {
            vertical-align: bottom;

            @include lg {
                font-size: 18px;
            }
        }
    }
}