/*!
 * Bootstrap Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

@font-face {
  font-family: 'PT Sans Narrow';
  src: url("../fonts/PTSansNarrow/PTSansNarrow-Regular.ttf");
  src: local("../fonts/PTSansNarrow/PTSansNarrow-Regular.ttf"), url("../fonts/PTSansNarrow/PTSansNarrow-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans Narrow';
  src: url("../fonts/PTSansNarrow/PTSansNarrow-Bold.ttf");
  src: local("../fonts/PTSansNarrow/PTSansNarrow-Bold.ttf"), url("../fonts/PTSansNarrow/PTSansNarrow-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.eot");
  src: local("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.woff") format("woff"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Roman.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.eot");
  src: local("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.woff") format("woff"), url("../fonts/HelveticaNeueCyr/HelveticaNeueCyr-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: "PT Sans Narrow", sans-serif;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  color: #000;
  text-align: left;
  background-color: #fff;
  min-height: 100vh;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #04509b;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover, a:focus, a:active {
    color: #000;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0;
  cursor: pointer; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

::selection {
  background-color: #04509b;
  color: #fff; }

.__typography h2,
.__typography h3,
.__typography h4,
.__typography h5,
.__typography h6 {
  color: #000;
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: left; }
  @media (min-width: 992px) {
    .__typography h2,
    .__typography h3,
    .__typography h4,
    .__typography h5,
    .__typography h6 {
      font-size: 35px;
      line-height: 1.2; } }
  @media (min-width: 992px) {
    .__typography h2:nth-of-type(n + 2),
    .__typography h3:nth-of-type(n + 2),
    .__typography h4:nth-of-type(n + 2),
    .__typography h5:nth-of-type(n + 2),
    .__typography h6:nth-of-type(n + 2) {
      margin-top: 65px; } }

.__typography a {
  text-decoration: underline; }
  .__typography a:hover, .__typography a:focus, .__typography a:active {
    text-decoration: none; }

.__typography p,
.__typography strong {
  font-family: "HelveticaNeueCyr", sans-serif;
  font-size: 14px;
  line-height: 1.55; }
  @media (min-width: 768px) {
    .__typography p,
    .__typography strong {
      font-size: 16px;
      line-height: 1.7; } }

.__typography p {
  margin-bottom: 15px; }

.__typography b, .__typography strong {
  font-family: "HelveticaNeueCyr", sans-serif;
  font-weight: bold; }

.__typography strong {
  display: inline-block; }

.__typography img {
  height: auto;
  margin: 30px auto 40px; }
  .__typography img + h2,
  .__typography img + h3,
  .__typography img + h4,
  .__typography img + h5,
  .__typography img + h6 {
    margin-top: 0; }

.__typography > img:first-child {
  margin-top: 0; }

.__typography ul {
  font-family: "HelveticaNeueCyr", sans-serif;
  list-style: none;
  margin-bottom: 20px;
  padding-left: 0; }
  .__typography ul li {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .__typography ul li {
        font-size: 16px;
        line-height: 1.45; } }
    .__typography ul li::before {
      content: "";
      display: block;
      height: 7px;
      width: 7px;
      background: #04509b;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 7px; }

.__typography .table-container {
  overflow-x: auto; }

.__typography table {
  font-family: "HelveticaNeueCyr", sans-serif;
  width: 100%;
  max-width: 100%;
  margin: 50px 0;
  background-color: #fff;
  border: 1px solid #c4c4c4; }
  .__typography table th,
  .__typography table td {
    padding: 15px 9px;
    vertical-align: top;
    border: 1px solid #c4c4c4;
    font-size: 14px;
    line-height: 1.2; }
    .__typography table th p,
    .__typography table th strong,
    .__typography table td p,
    .__typography table td strong {
      line-height: 1.3; }
      .__typography table th p:last-child,
      .__typography table th strong:last-child,
      .__typography table td p:last-child,
      .__typography table td strong:last-child {
        margin-bottom: 0; }
  @media (min-width: 992px) {
    .__typography table td {
      font-size: 16px; } }
  .__typography table thead th {
    vertical-align: bottom; }
    @media (min-width: 992px) {
      .__typography table thead th {
        font-size: 18px; } }

.icon-add {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../img/svg/icon-add-white.svg);
  height: 23px;
  width: 23px;
  display: inline-block; }

.icon-search {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../img/svg/search-white.svg);
  height: 23px;
  width: 23px;
  display: inline-block; }

.page-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 28px;
  text-align: left;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .page-title {
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 65px; } }

.scrollbar-thumb {
  border-radius: 5px; }

.link-to-top {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  position: fixed;
  right: 10px;
  bottom: 50px;
  height: 50px;
  opacity: 0;
  visibility: hidden;
  width: 50px;
  transition: 0.4s ease-in-out;
  z-index: 999; }
  .link-to-top::before {
    content: '';
    border-width: 5px 16px 16px 5px;
    border-color: #fff transparent transparent #fff;
    transform: rotate(45deg);
    border-style: solid;
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 10px); }
  .link-to-top.__show {
    opacity: .6;
    visibility: visible; }
    .link-to-top.__show:hover {
      cursor: pointer;
      opacity: 1; }

.custom-object-fit {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  .custom-object-fit img {
    opacity: 0; }

.btn {
  background-color: #04509b;
  border-radius: 30px;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out; }
  @media (min-width: 768px) {
    .btn {
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      padding: 0 25px; } }
  .btn:hover, .btn:focus, .btn:active {
    background-color: #258cc6;
    color: #fff;
    text-decoration: none; }
  .btn.__outline {
    border: 1px solid #04509b;
    background: transparent;
    color: #04509b; }
    @media (max-width: 767.98px) {
      .btn.__outline {
        line-height: 38px; } }
    .btn.__outline:hover, .btn.__outline:focus, .btn.__outline:active {
      background-color: #04509b;
      color: #fff;
      text-decoration: none; }
  .btn.__profit {
    background-color: #97c23c;
    font-size: 14px; }
    @media (min-width: 768px) {
      .btn.__profit {
        font-size: 20px; } }
    .btn.__profit:hover, .btn.__profit:focus, .btn.__profit:active {
      background-color: rgba(151, 194, 60, 0.8); }
    .btn.__profit[disabled] {
      opacity: 0.5;
      cursor: default; }
    .btn.__profit#load_more {
      margin: 0 auto;
      display: block;
      position: relative; }
  .btn.__icon {
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    .btn.__icon i {
      margin-right: 10px; }

.social {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0; }
  .social-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    height: 30px;
    transition: all 0.4s;
    width: 30px;
    margin-right: 5px; }
    .social-item.__instagram {
      background-image: url(../img/svg/instagram.svg); }
    .social-item.__twitter {
      background-image: url(../img/svg/twitter.svg); }
    .social-item.__facebook {
      background-image: url(../img/svg/facebook.svg); }
    .social-item.__youtube {
      background-image: url(../img/svg/youtube.svg); }
    .social-item.__telegram {
      background-image: url(../img/svg/telegram.svg); }
    .social-item.__viber {
      background-image: url(../img/svg/viber.svg); }
    .social-item:hover {
      opacity: .7; }
    .social-item a {
      display: block;
      height: 100%;
      width: 100%; }
  .social-share {
    align-items: center;
    background: #ededed;
    display: flex;
    height: 66px;
    padding: 0 20px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .social-share {
        background: transparent;
        padding-left: 0; } }
    .social-share-title {
      font-size: 20px; }
    .social-share .social {
      margin-bottom: 0; }
      .social-share .social-item {
        margin-left: 0; }
        @media all and (max-width: 991px) {
          .social-share .social-item {
            margin-left: 20px; } }

section {
  padding: 45px 0; }
  @media (min-width: 992px) {
    section {
      padding: 95px 0; } }
  section h2 {
    font-size: 25px;
    font-weight: bold;
    line-height: 28px;
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      section h2 {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 65px; } }

/*PRELOADING------------ */
.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background: #c4c4c4;
  overflow: hidden;
  min-height: 100vh; }
  .preloader-inner {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: fixed;
    z-index: 101;
    border: 4px solid #97c23c;
    top: 50%;
    left: 50%;
    animation: preloader 2s infinite ease; }
    .preloader-inner::after {
      content: '';
      vertical-align: top;
      display: inline-block;
      width: 100%;
      background-color: #97c23c;
      animation: preloader-inner 2s infinite ease-in; }

@keyframes preloader {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(180deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes preloader-inner {
  0% {
    height: 0%; }
  25% {
    height: 0%; }
  50% {
    height: 100%; }
  75% {
    height: 100%; }
  100% {
    height: 0%; } }

@media (max-width: 991.98px) {
  .upcoming-training-container {
    display: none; } }

.upcoming-training-title {
  font-size: 25px;
  line-height: 50px; }
  @media (min-width: 1280px) {
    .upcoming-training-title {
      font-size: 30px;
      line-height: 60px; } }

.upcoming-training-item {
  background: #f5f5f5;
  margin-bottom: 16px;
  padding: 20px;
  text-align: center; }
  @media (min-width: 1280px) {
    .upcoming-training-item {
      padding: 30px; } }
  .upcoming-training-item:last-child {
    margin-bottom: 0; }
  .upcoming-training-item-info {
    align-items: center;
    display: flex;
    justify-content: space-between; }
  .upcoming-training-item-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 40px;
    margin-top: 25px;
    text-align: left; }
  .upcoming-training-item-date {
    font-size: 16px;
    font-weight: bold;
    line-height: 28px; }
    @media (min-width: 1280px) {
      .upcoming-training-item-date {
        font-size: 22px; } }
  .upcoming-training-item-city {
    font-size: 20px;
    line-height: 26px; }
    @media (min-width: 992px) {
      .upcoming-training-item-city {
        font-size: 16px; } }
    @media (min-width: 1280px) {
      .upcoming-training-item-city {
        font-size: 20px; } }

.banner {
  padding: 70px 0 15px; }
  @media (min-width: 992px) {
    .banner {
      padding-bottom: 5px;
      padding-top: 150px; } }
  .banner-link {
    background: #121d2e;
    display: flex;
    margin: 0 auto;
    padding: 25px 25px 30px;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 576px) {
      .banner-link {
        flex-direction: row;
        justify-content: space-between;
        padding-left: 50px;
        padding-right: 50px;
        max-width: 80%; } }
    @media (min-width: 992px) {
      .banner-link {
        padding: 50px 115px;
        justify-content: space-between; } }
    @media (min-width: 1280px) {
      .banner-link {
        max-width: 1030px; } }
  .banner-logo {
    margin-bottom: 15px;
    width: 115px; }
    @media (min-width: 576px) {
      .banner-logo {
        margin-bottom: 0; } }
    @media (min-width: 768px) {
      .banner-logo {
        width: 200px; } }
  .banner-slogan {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    color: #97c23c;
    text-align: center;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .banner-slogan {
        font-size: 50px;
        line-height: 55px; } }

.pagination {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0;
  margin-top: 50px; }
  @media (min-width: 992px) {
    .pagination {
      margin-top: 20px; } }
  .pagination-item {
    margin-right: 13px;
    transition: all 0.4s; }
    .pagination-item:last-child {
      margin-right: 0; }
    .pagination-item span.active, .pagination-item span.current, .pagination-item a.active, .pagination-item a.current {
      background: #97c23c;
      color: #fff; }
      .pagination-item span.active:hover, .pagination-item span.active:focus, .pagination-item span.active:active, .pagination-item span.current:hover, .pagination-item span.current:focus, .pagination-item span.current:active, .pagination-item a.active:hover, .pagination-item a.active:focus, .pagination-item a.active:active, .pagination-item a.current:hover, .pagination-item a.current:focus, .pagination-item a.current:active {
        background: rgba(151, 194, 60, 0.8); }
    .pagination-item.prev a {
      position: relative; }
      .pagination-item.prev a::before {
        content: '';
        border: solid #000;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: translate(-50%, -50%) rotate(135deg);
        position: absolute;
        left: 50%;
        top: 50%;
        transition: all .2s; }
      .pagination-item.prev a:hover, .pagination-item.prev a:focus, .pagination-item.prev a:active {
        background: rgba(151, 194, 60, 0.25); }
    .pagination-item a.next {
      position: relative; }
      .pagination-item a.next::before {
        content: '';
        border: solid #000;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: translate(-50%, -50%) rotate(-45deg);
        position: absolute;
        left: 50%;
        top: 50%;
        transition: all .2s; }
      .pagination-item a.next:hover, .pagination-item a.next:focus, .pagination-item a.next:active {
        background: rgba(151, 194, 60, 0.25); }
    .pagination-item.disabled a {
      cursor: not-allowed;
      opacity: .5;
      pointer-events: none; }
    .pagination-item a, .pagination-item span {
      color: #000;
      border-radius: 50%;
      font-size: 18px;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      transition: all 0.4s; }
      .pagination-item a:hover, .pagination-item a:focus, .pagination-item a:active, .pagination-item span:hover, .pagination-item span:focus, .pagination-item span:active {
        background: rgba(151, 194, 60, 0.25);
        text-decoration: none; }

.filters_mobile-show {
  align-items: center;
  background-image: url(../img/svg/filter-btn-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1; }
  @media (min-width: 992px) {
    .filters_mobile-show {
      display: none; } }
  .filters_mobile-show::before {
    background: #04509b;
    bottom: 0;
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    width: 100%; }
  .filters_mobile-show-btn {
    border: none;
    color: #fff;
    background: transparent;
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    height: 50px;
    height: 50px;
    width: auto;
    position: relative;
    padding: 0 0 0 25px;
    outline: none; }
    .filters_mobile-show-btn::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 14px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../img/svg/icon-filter.svg); }

.filters_mobile-actions {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1051;
  background-color: #fff;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  outline: none; }
  .filters_mobile-actions:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 12px;
    bottom: 0;
    background: #04509b;
    display: block;
    left: 0; }
  .filters_mobile-actions-container {
    display: flex; }
  .filters_mobile-actions-cancel {
    background: #fff;
    border: 0;
    width: 50%;
    font-size: 20px;
    line-height: 38px;
    height: 38px;
    border-radius: 25px;
    position: relative;
    z-index: 3;
    outline: none; }
  .filters_mobile-actions-apply {
    background-color: #04509b;
    color: #fff;
    border: 0;
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    line-height: 38px;
    height: 38px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    padding: 0; }
    .filters_mobile-actions-apply::before {
      content: '';
      display: block;
      background: #04509b;
      position: absolute;
      bottom: 0;
      left: -10px;
      height: 12px;
      width: 12px;
      z-index: 1; }
    .filters_mobile-actions-apply::after {
      content: '';
      display: block;
      background: #04509b;
      position: absolute;
      bottom: 0;
      right: -10px;
      height: 12px;
      width: 12px;
      z-index: 1; }
    .filters_mobile-actions-apply span {
      display: block;
      width: 100%;
      height: 100%;
      position: relative; }
      .filters_mobile-actions-apply span::before {
        content: '';
        display: block;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: -26px;
        height: 26px;
        width: 26px;
        z-index: 2;
        border-radius: 50%; }
      .filters_mobile-actions-apply span::after {
        content: '';
        display: block;
        background: #fff;
        position: absolute;
        bottom: 0;
        right: -26px;
        height: 26px;
        width: 26px;
        z-index: 2;
        border-radius: 50%; }

.filters-selected {
  align-items: center;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .filters-selected {
      margin-bottom: 14px; } }
  .filters-selected-item {
    font-size: 12px;
    line-height: 210%;
    color: #474747;
    position: relative;
    background: rgba(107, 199, 42, 0.31);
    padding: 0 20px 0 9px;
    line-height: 21px;
    display: inline-flex;
    align-items: center;
    margin-right: 13px;
    border-radius: 10px;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 17px; }
    .filters-selected-item::after {
      content: "";
      display: block;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      height: 7px;
      width: 7px;
      background-image: url(../img/svg/close.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .filters-selected-item:hover {
      background: rgba(107, 199, 42, 0.5); }
  .filters-selected-reset {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 17px;
    color: #04509B; }

.side-banner {
  display: none; }
  @media (min-width: 992px) {
    .side-banner {
      display: inline-block;
      text-align: center;
      width: 100%;
      max-width: 400px; } }
  @media (max-width: 1279.98px) {
    .side-banner {
      margin-bottom: 20px; } }
  @media (min-width: 992px) {
    .side-banner {
      margin-top: 20px; } }
  .side-banner img {
    width: 100%; }

.team_section {
  padding: 0;
  text-align: center; }
  .team_section .team-slider {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .team_section .team-slider {
        margin-bottom: 30px; } }
    .team_section .team-slider-item-link {
      border-radius: 5px;
      color: #000;
      display: block;
      margin-bottom: 0;
      padding: 15px 3px 20px;
      text-align: center;
      transition: all 0.4s; }
      @media (min-width: 992px) {
        .team_section .team-slider-item-link {
          padding: 30px 24px 50px; } }
      .team_section .team-slider-item-link:hover, .team_section .team-slider-item-link:focus, .team_section .team-slider-item-link:active {
        color: #000;
        text-decoration: none; }
    .team_section .team-slider-item-image {
      border-radius: 50%;
      display: inline-block;
      height: 82px;
      margin-bottom: 20px;
      width: 82px; }
      @media (min-width: 992px) {
        .team_section .team-slider-item-image {
          height: 246px;
          margin-bottom: 30px;
          width: 246px; } }
    .team_section .team-slider-item-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 0;
      min-height: 44px; }
      @media (min-width: 992px) {
        .team_section .team-slider-item-name {
          font-size: 22px;
          margin-bottom: 10px;
          min-height: auto; } }
    .team_section .team-slider-item-position {
      font-size: 16px;
      line-height: 1.4;
      opacity: .6; }
      @media (min-width: 992px) {
        .team_section .team-slider-item-position {
          font-size: 16px; } }
    .team_section .team-slider .slick-arrow {
      top: 20%; }
      @media (min-width: 992px) {
        .team_section .team-slider .slick-arrow {
          top: 135px; } }

.read_program {
  padding: 30px 0 75px;
  text-align: center; }
  @media (min-width: 992px) {
    .read_program {
      padding: 40px 0 135px; } }
  .read_program-title {
    margin-bottom: 8px; }
    @media (min-width: 992px) {
      .read_program-title {
        margin-bottom: 12px; } }
  .read_program-txt {
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .read_program-txt {
        font-size: 35px;
        line-height: 1.8; } }
  .read_program .btn {
    margin-top: 40px; }
    @media (min-width: 992px) {
      .read_program .btn {
        margin-top: 70px; } }

.header-container {
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 540px;
  position: relative;
  padding: 14px 5px 14px 15px; }
  @media (min-width: 768px) {
    .header-container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .header-container {
      max-width: calc(100% - 2%);
      padding: 25px 0; } }
  @media (min-width: 1280px) {
    .header-container {
      max-width: 1240px; } }

.header a:hover, .header a:focus, .header a:active {
  text-decoration: none; }

.header-logo {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 992px) {
    .header-logo {
      flex: 0 0 12%;
      max-width: 12%; } }
  @media (min-width: 1280px) {
    .header-logo {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; } }
  .header-logo a {
    display: inline-block; }
  .header-logo img {
    max-width: 98px; }

.header-nav {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 992px) {
    .header-nav {
      flex: 0 0 88%;
      max-width: 88%;
      justify-content: space-between;
      position: relative; } }
  @media (min-width: 1280px) {
    .header-nav {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }
  @media (max-width: 991.98px) {
    .header-nav-menu {
      display: none;
      padding: 30px 45px;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      background: #f0f0f0;
      z-index: 2; }
      .header-nav-menu::before {
        content: '';
        display: block;
        height: 1px;
        width: 0;
        background: #04509b;
        transition: width 0.4s;
        position: absolute;
        left: 0;
        top: 0; } }
  @media (min-width: 992px) {
    .header-nav-menu {
      display: flex; } }
  .header-nav-menu.__open::before {
    width: 100%;
    transition: width 0.4s; }
  @media (max-width: 991.98px) {
    .header-nav-menu.__submenu-opened {
      padding-left: 58px; }
      .header-nav-menu.__submenu-opened .menu-item:not(.__open),
      .header-nav-menu.__submenu-opened .header-nav-menu-bottom {
        display: none; } }
  .header-nav-menu .menu {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    @media (min-width: 992px) {
      .header-nav-menu .menu {
        display: flex; } }
    .header-nav-menu .menu-item {
      position: relative;
      transition: all 0.4s; }
      @media (min-width: 992px) {
        .header-nav-menu .menu-item {
          border-radius: 20px;
          line-height: 26px; } }
      .header-nav-menu .menu-item:nth-child(n + 2) {
        margin-top: 20px; }
        @media (min-width: 992px) {
          .header-nav-menu .menu-item:nth-child(n + 2) {
            margin-left: 10px;
            margin-top: 0; } }
        @media all and (min-width: 1100px) {
          .header-nav-menu .menu-item:nth-child(n + 2) {
            margin-left: 15px; } }
      .header-nav-menu .menu-item > a {
        color: #000;
        font-size: 20px;
        font-weight: bold; }
        @media (min-width: 992px) {
          .header-nav-menu .menu-item > a {
            font-size: 18px;
            padding: 0 8px; } }
        @media (min-width: 1280px) {
          .header-nav-menu .menu-item > a {
            padding: 0 15px; } }
      @media (min-width: 992px) {
        .header-nav-menu .menu-item:hover {
          background: #258cc6; }
          .header-nav-menu .menu-item:hover > a {
            color: #fff; }
            .header-nav-menu .menu-item:hover > a::after {
              border-color: #fff; } }
      .header-nav-menu .menu-item.__dropdown.__open {
        margin-top: 0; }
        @media (min-width: 992px) {
          .header-nav-menu .menu-item.__dropdown.__open {
            background: #04509b; } }
        .header-nav-menu .menu-item.__dropdown.__open .sub-menu {
          display: block; }
        .header-nav-menu .menu-item.__dropdown.__open > a {
          color: #000; }
          @media (min-width: 992px) {
            .header-nav-menu .menu-item.__dropdown.__open > a {
              color: #fff; } }
          .header-nav-menu .menu-item.__dropdown.__open > a::after {
            content: '';
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            transform: translateY(-50%) rotate(-225deg);
            position: absolute;
            right: auto;
            left: -15px;
            top: 50%; }
            @media (min-width: 992px) {
              .header-nav-menu .menu-item.__dropdown.__open > a::after {
                border-color: #fff;
                left: auto;
                top: 50%;
                transform: rotate(-135deg);
                right: 15px; } }
      @media (min-width: 992px) {
        .header-nav-menu .menu-item.__dropdown:hover > a {
          color: #fff; }
          .header-nav-menu .menu-item.__dropdown:hover > a::after {
            border-color: #fff;
            right: 15px; } }
      .header-nav-menu .menu-item.__dropdown > a {
        cursor: pointer;
        color: #000;
        position: relative;
        padding-right: 30px; }
        .header-nav-menu .menu-item.__dropdown > a::after {
          content: '';
          border: solid black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 3px;
          transform: translateY(-50%) rotate(-45deg);
          position: absolute;
          right: 15px;
          top: 50%;
          transition: all 0.2s; }
          @media (min-width: 992px) {
            .header-nav-menu .menu-item.__dropdown > a::after {
              top: calc(50% - 5px);
              transform: rotate(45deg); } }
  .header-nav-menu-bottom {
    margin-top: 15px;
    display: flex;
    align-items: center;
    margin-left: -15px; }
    @media (min-width: 992px) {
      .header-nav-menu-bottom {
        display: none; } }
    .header-nav-menu-bottom .__email {
      height: 25px;
      width: 25px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px;
      padding: 15px;
      cursor: pointer;
      background-image: url(../img/svg/email.svg);
      display: inline-block;
      margin-right: 85px;
      box-sizing: content-box; }
    .header-nav-menu-bottom .header-nav-lang {
      position: relative; }
      .header-nav-menu-bottom .header-nav-lang .sub-menu {
        position: absolute;
        right: 0;
        bottom: 100%;
        display: none; }
  .header-nav .sub-menu {
    list-style: none;
    padding-left: 0;
    display: none; }
    @media (min-width: 992px) {
      .header-nav .sub-menu {
        position: absolute;
        background: #fff;
        border: 5px;
        padding: 25px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        top: calc(100% + 30px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2; } }
    .header-nav .sub-menu-item {
      margin-top: 20px; }
      @media (min-width: 992px) {
        .header-nav .sub-menu-item {
          margin-top: 0;
          padding-bottom: 15px; }
          .header-nav .sub-menu-item:last-child {
            padding-bottom: 0; } }
      .header-nav .sub-menu-item a {
        color: #000;
        font-size: 18px; }
        .header-nav .sub-menu-item a:hover, .header-nav .sub-menu-item a:focus, .header-nav .sub-menu-item a:active {
          color: #04509b; }
        @media (min-width: 992px) {
          .header-nav .sub-menu-item a {
            font-size: 20px;
            white-space: nowrap; } }
  .header-nav-right {
    align-items: center;
    display: flex; }
    @media (max-width: 991.98px) {
      .header-nav-right .header-nav-lang {
        display: none; } }
  .header-nav-icons {
    display: flex; }
    .header-nav-icons button {
      background-color: transparent;
      border: 0; }
    .header-nav-icons-item {
      height: 26px;
      width: 26px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 16px;
      padding: 0;
      cursor: pointer;
      box-sizing: content-box;
      text-align: center;
      border-radius: 50%; }
      @media (max-width: 991.98px) {
        .header-nav-icons-item {
          height: 40px;
          width: 40px;
          box-sizing: content-box;
          display: flex;
          align-items: center;
          justify-content: center; } }
      .header-nav-icons-item:hover {
        background-color: #258cc6; }
      .header-nav-icons-item.__phones svg {
        fill: #000; }
      .header-nav-icons-item.__phones:hover svg {
        fill: #fff; }
      .header-nav-icons-item.__email {
        display: none; }
        @media (min-width: 992px) {
          .header-nav-icons-item.__email {
            display: inline-block; } }
        .header-nav-icons-item.__email:hover svg {
          stroke: #fff; }
        .header-nav-icons-item.__email svg {
          stroke: #000; }
      .header-nav-icons-item.__search:hover svg {
        stroke: #fff; }
      .header-nav-icons-item.__search svg {
        stroke: #000; }
      .header-nav-icons-item:nth-child(n + 2) {
        margin-left: 15px; }
        @media (min-width: 1280px) {
          .header-nav-icons-item:nth-child(n + 2) {
            margin-left: 25px; } }
      .header-nav-icons-item.__dropdown {
        position: relative; }
        .header-nav-icons-item.__dropdown.__open {
          background: #04509b;
          margin-top: 0; }
          .header-nav-icons-item.__dropdown.__open.__phones svg {
            fill: #fff; }
          .header-nav-icons-item.__dropdown.__open .sub-menu {
            display: block; }
            @media (max-width: 991.98px) {
              .header-nav-icons-item.__dropdown.__open .sub-menu {
                background: #fff;
                border-radius: 5px;
                border: 5px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
                left: 50%;
                padding: 25px;
                position: absolute;
                top: calc(100% + 30px);
                transform: translateX(-50%);
                width: 240px;
                z-index: 1; } }
        .header-nav-icons-item.__dropdown > a {
          position: relative;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center; }
        .header-nav-icons-item.__dropdown .sub-menu::before {
          content: '';
          width: 0;
          height: 0;
          border: 10px solid black;
          border-color: transparent transparent #fff #fff;
          position: absolute;
          top: 8px;
          left: calc(50% + 5px);
          transform: rotate(135deg) translateX(-50%);
          transform-origin: 0 0;
          box-shadow: -3px 2px 3px 0 rgba(0, 0, 0, 0.15); }
  .header-nav-lang {
    margin-left: 10px; }
    @media (min-width: 1280px) {
      .header-nav-lang {
        margin-left: 30px; } }
    .header-nav-lang a {
      display: inline-block;
      line-height: 26px;
      padding-right: 36px;
      position: relative;
      color: #000; }
      .header-nav-lang a::after {
        content: '';
        display: block;
        height: 26px;
        width: 26px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: contain; }
      .header-nav-lang a.__active {
        border-radius: 20px;
        font-weight: bold;
        padding-left: 15px; }
        .header-nav-lang a.__active:hover {
          background: #258cc6;
          color: #fff; }
      .header-nav-lang a.__uk::after {
        background-image: url(../img/icons/ukraine.png); }
      .header-nav-lang a.__en::after {
        background-image: url(../img/icons/britain.png); }
      .header-nav-lang a.__ru::after {
        background-image: url(../img/icons/russia.png); }
    .header-nav-lang.__dropdown {
      position: relative; }
      .header-nav-lang.__dropdown.__open .__active {
        background: #04509b;
        color: #fff; }
      .header-nav-lang.__dropdown.__open .sub-menu {
        display: block; }
      .header-nav-lang.__dropdown > a {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center; }
      .header-nav-lang.__dropdown .sub-menu::before {
        content: '';
        width: 0;
        height: 0;
        border: 10px solid black;
        border-color: transparent transparent #fff #fff;
        position: absolute;
        top: 8px;
        left: calc(50% + 5px);
        transform: rotate(135deg) translateX(-50%);
        transform-origin: 0 0;
        box-shadow: -3px 2px 3px 0 rgba(0, 0, 0, 0.15); }
    .header-nav-lang.__popup .sub-menu {
      background: #f0f0f0; }
      .header-nav-lang.__popup .sub-menu-item a {
        padding-left: 15px; }

.header .burger {
  background: transparent;
  border: 0;
  position: relative;
  height: 30px;
  width: 30px;
  padding: 5px;
  flex-shrink: 0;
  box-sizing: content-box;
  cursor: pointer;
  margin-left: 15px;
  transition: all 0.4s ease-in-out; }
  .header .burger::before, .header .burger::after {
    content: '';
    display: inline-block;
    height: 3px;
    border-radius: 3px;
    width: 20px;
    background: #000;
    position: absolute;
    left: calc(50% - 10px);
    transition: all 0.2s ease-in-out; }
  .header .burger::before {
    top: calc(50% - 5px); }
  .header .burger::after {
    top: calc(50% + 2px); }
  .header .burger.__open::before {
    top: calc(50% - 2px);
    transform: rotate(45deg); }
  .header .burger.__open::after {
    top: calc(50% - 2px);
    transform: rotate(-45deg); }
  @media (min-width: 992px) {
    .header .burger {
      display: none; } }

.footer {
  border-top: 1px solid #04509b;
  margin-top: auto; }
  .footer-main {
    padding: 22px 0 46px; }
    @media (min-width: 768px) {
      .footer-main {
        padding-top: 50px; } }
    @media (min-width: 992px) {
      .footer-main {
        padding: 100px 0 60px; } }
    .footer-main-logo {
      margin-bottom: 30px;
      text-align: center; }
      @media (min-width: 992px) {
        .footer-main-logo {
          text-align: left; } }
      .footer-main-logo img {
        max-width: 97px; }
    .footer-main-profit {
      text-align: center; }
      @media (min-width: 992px) {
        .footer-main-profit {
          text-align: left; } }
      .footer-main-profit a {
        display: inline-block; }
      .footer-main-profit-link {
        align-items: center;
        color: #04509b;
        display: flex;
        font-size: 18px;
        justify-content: center;
        margin-top: 5px; }
        @media (min-width: 992px) {
          .footer-main-profit-link {
            justify-content: flex-start; } }
  .footer-nav {
    margin-bottom: 15px; }
    .footer-nav-menu {
      list-style: none;
      padding-left: 0; }
      .footer-nav-menu-item {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 3px; }
        .footer-nav-menu-item:last-child {
          margin-bottom: 0; }
        @media (min-width: 992px) {
          .footer-nav-menu-item {
            font-size: 18px; } }
        .footer-nav-menu-item a {
          color: #000; }
          .footer-nav-menu-item a:hover, .footer-nav-menu-item a:focus, .footer-nav-menu-item a:active {
            color: #04509b;
            text-decoration: underline; }
  .footer-rules {
    font-size: 14px;
    line-height: 21px;
    max-width: 350px; }
    @media (max-width: 991.98px) {
      .footer-rules {
        display: none; } }
  .footer-form {
    margin-bottom: 45px;
    margin-top: 60px;
    text-align: center; }
    @media (min-width: 992px) {
      .footer-form {
        margin-top: 0; } }
    .footer-form-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center; }
    .footer-form input {
      border-radius: 20px;
      color: #898989;
      display: block;
      font-size: 14px;
      margin: 0 auto 18px;
      max-width: 100%;
      padding: 0 5px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 225px; }
    .footer-form button {
      font-size: 14px;
      height: 35px;
      line-height: 35px;
      max-width: 100%;
      padding: 0 5px;
      width: 225px; }
  .footer-bottom {
    border-top: 1px solid #04509b;
    font-size: 14px;
    padding: 20px 10px;
    text-align: center; }

.slick {
  /* Slider Default styles*/ }
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    transform: translate3d(0, 0, 0); }
    .slick-list:focus {
      outline: none; }
    .slick-list.dragging {
      cursor: pointer;
      cursor: hand; }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: translate3d(0, 0, 0); }
    .slick-track:before, .slick-track:after {
      display: table;
      content: ''; }
    .slick-track:after {
      clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }
    .slick-slide img {
      display: block; }
    .slick-slide.slick-loading img {
      display: none; }
    .slick-slide.dragging img {
      pointer-events: none; }
  .slick [dir='rtl'] .slick-slide {
    float: right; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-arrow.slick-hidden {
    display: none; }
  .slick-dots {
    bottom: 20px;
    font-size: 0;
    left: 50%;
    line-height: 0;
    list-style: none;
    margin: 0 auto;
    padding-left: 0;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 100%; }
    @media (min-width: 768px) {
      .slick-dots {
        bottom: 36px; } }
    .slick-dots li {
      display: inline-block;
      margin-right: 4px;
      padding: 4px; }
      @media (min-width: 768px) {
        .slick-dots li {
          padding: 5px; } }
      .slick-dots li.slick-active button {
        background: #04509b; }
      .slick-dots li button {
        background: #c8c8c8;
        border-radius: 2px;
        border: 0;
        height: 5px;
        transition: all 0.4s ease-in-out;
        width: 30px; }
  .slick-arrow {
    background-color: transparent;
    background-image: url(../img/svg/slider-arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border: 0;
    box-sizing: border-box;
    font-size: 0;
    height: 40px;
    line-height: 0;
    padding: 5px;
    position: absolute;
    top: 5px;
    transition: all 0.4s;
    width: 20px;
    z-index: 1; }
    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
      opacity: .7; }
  .slick-prev {
    left: 0;
    transform: rotate(180deg); }
  .slick-next {
    right: 0; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }
  .modal-open {
    overflow: hidden; }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
    .modal.fade .modal-dialog {
      transition: transform 0.3s ease-out;
      transform: translate(0, -25%); }
    .modal.show .modal-dialog {
      transform: translate(0, 0); }
    .modal-dialog-centered {
      display: flex;
      align-items: center;
      min-height: calc(100% - (0.5rem * 2)); }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0; }
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000; }
    .modal-backdrop.fade {
      opacity: 0; }
    .modal-backdrop.show {
      opacity: 0.5; }
  .modal-header {
    background: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem; }
    .modal-header .close {
      background: transparent;
      height: 47px;
      width: 47px;
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.4s; }
      .modal-header .close:hover {
        opacity: .8; }
      .modal-header .close span {
        display: inline-block;
        height: 2px;
        width: 20px;
        background: #000;
        position: absolute;
        top: 50%;
        left: 50%; }
        .modal-header .close span:first-child {
          transform: translateX(-50%) rotate(45deg); }
        .modal-header .close span:last-child {
          transform: translateX(-50%) rotate(-45deg); }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .modal-body {
    position: relative;
    flex: 1 1 auto; }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef; }
    .modal-footer > :not(:first-child) {
      margin-left: .25rem; }
    .modal-footer > :not(:last-child) {
      margin-right: .25rem; }
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 576px) {
    .modal .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
      .modal .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2)); }
    .modal .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    .modal .modal-lg {
      max-width: 800px; } }
  .modal-search .modal-dialog {
    max-width: 100%; }
    @media (min-width: 576px) {
      .modal-search .modal-dialog {
        max-width: 90%; } }
    @media (min-width: 768px) {
      .modal-search .modal-dialog {
        max-width: 80%; } }
    @media (min-width: 1280px) {
      .modal-search .modal-dialog {
        max-width: 1240px; } }
  .modal-search .modal-content {
    background: transparent;
    border: 0; }
    .modal-search .modal-content .modal-header {
      margin-bottom: 57px; }
      @media (min-width: 992px) {
        .modal-search .modal-content .modal-header {
          margin-bottom: 48px; } }
  .modal-search .close {
    background: transparent;
    height: 47px;
    width: 47px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    transition: all 0.4s; }
    .modal-search .close:hover {
      opacity: .8; }
    .modal-search .close span {
      display: inline-block;
      height: 2px;
      width: 26px;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%; }
      .modal-search .close span:first-child {
        transform: translateX(-50%) rotate(45deg); }
      .modal-search .close span:last-child {
        transform: translateX(-50%) rotate(-45deg); }
  .modal-search-form {
    position: relative;
    width: 100%; }
    .modal-search-form input {
      background: #fff;
      border-radius: 5px;
      height: 60px;
      margin-bottom: 37px;
      width: 100%; }
      @media (min-width: 992px) {
        .modal-search-form input {
          height: 78px;
          margin-bottom: 0;
          padding-right: 120px; } }
    .modal-search-form button {
      height: 40px;
      line-height: 40px;
      width: 100%; }
      @media (min-width: 992px) {
        .modal-search-form button {
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translateY(-50%);
          width: auto; } }
  .modal-training .modal-dialog {
    max-width: 992px; }
  .modal-training .modal-content {
    padding: 50px 25px; }
    @media (min-width: 992px) {
      .modal-training .modal-content {
        padding: 50px 80px; } }
  .modal-training .modal-header {
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .modal-training .modal-header {
        margin-bottom: 30px; } }
  .modal-training .modal-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .modal-training .modal-title {
        font-size: 30px;
        line-height: 39px; } }
  .modal-training-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 40px;
    text-align: center; }
    @media (min-width: 992px) {
      .modal-training-name {
        font-size: 22px; } }
  .modal-training-icons {
    font-size: 22px;
    line-height: 28px;
    display: flex;
    margin-bottom: 35px;
    flex-direction: column;
    align-items: center; }
    @media (min-width: 992px) {
      .modal-training-icons {
        align-items: center;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 55px; } }
    .modal-training-icons-item {
      align-items: center;
      display: flex; }
      @media (min-width: 992px) {
        .modal-training-icons-item {
          margin-right: 75px; }
          .modal-training-icons-item:last-child {
            margin-right: 0; } }
      .modal-training-icons-item img {
        margin-right: 18px; }
  .modal-training-form-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .modal-training-form-inputs {
        align-items: center;
        justify-content: space-between;
        flex-direction: row; } }
    .modal-training-form-inputs input {
      border-color: #898989;
      font-weight: normal;
      width: 100%;
      margin-bottom: 15px;
      max-width: 320px; }
      @media (min-width: 992px) {
        .modal-training-form-inputs input {
          width: 265px; } }
  .modal-training-form-captcha {
    margin: 0 auto 24px;
    text-align: center; }
    .modal-training-form-captcha #apply_seminar--recaptcha > div {
      margin: 0 auto; }
  .modal-training-form-oferta {
    margin-bottom: 45px;
    text-align: center; }
    .modal-training-form-oferta a {
      color: #04509b;
      text-decoration: underline; }
      .modal-training-form-oferta a:hover, .modal-training-form-oferta a:focus, .modal-training-form-oferta a:active {
        text-decoration: none; }
  .modal-training-form-buttons {
    align-items: center;
    display: flex;
    flex-direction: column; }
    @media (min-width: 992px) {
      .modal-training-form-buttons {
        align-items: center;
        flex-direction: row;
        justify-content: center; } }
    .modal-training-form-buttons button {
      margin-bottom: 15px;
      max-width: 320px;
      padding: 0 10px;
      width: 100%; }
      @media (min-width: 992px) {
        .modal-training-form-buttons button {
          margin-right: 20px;
          margin-bottom: 0;
          width: 190px; } }
      .modal-training-form-buttons button:last-child {
        margin-right: 0;
        margin-bottom: 0; }
  .modal.filters-mobile .modal-dialog {
    margin: 0;
    max-width: none;
    width: 100%; }
  .modal.filters-mobile .modal-content {
    padding-bottom: 50px; }
  .modal.filters-mobile .filters-mobile-form-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 10px;
    padding: 0 20px; }
  .modal.filters-mobile .filters-mobile-form .filters-block-name {
    background: #ededed;
    height: 52px;
    padding: 0 20px;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 52px;
    color: #676767; }
  .modal.filters-mobile .filters-mobile-form .filters-block-container {
    padding: 20px; }
    .modal.filters-mobile .filters-mobile-form .filters-block-container .filters-item {
      margin-bottom: 20px; }
      .modal.filters-mobile .filters-mobile-form .filters-block-container .filters-item:last-child {
        margin-bottom: 0; }
      .modal.filters-mobile .filters-mobile-form .filters-block-container .filters-item .form-checkbox:checked + .form-checkbox-label {
        font-weight: bold; }
        .modal.filters-mobile .filters-mobile-form .filters-block-container .filters-item .form-checkbox:checked + .form-checkbox-label::before {
          background-color: #04509b; }
      .modal.filters-mobile .filters-mobile-form .filters-block-container .filters-item .form-checkbox-label {
        font-size: 20px;
        line-height: 1;
        margin-bottom: 0; }
        .modal.filters-mobile .filters-mobile-form .filters-block-container .filters-item .form-checkbox-label::before {
          background-color: #fff;
          border-color: #04509b; }

form input,
form textarea {
  border-radius: 25px;
  border: 1px solid #898989;
  color: #04509b;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  padding: 0 20px; }
  @media (min-width: 768px) {
    form input,
    form textarea {
      font-size: 20px;
      font-weight: normal;
      text-align: left; } }
  form input ::placeholder,
  form textarea ::placeholder {
    color: #f5f5f5; }
  form input.validation-item.error,
  form textarea.validation-item.error {
    border-color: #f00;
    transition: all 0.2s; }

form input {
  line-height: 38px;
  text-align: center; }
  @media (min-width: 768px) {
    form input {
      line-height: 48px; } }

form textarea {
  line-height: 1.4;
  padding-top: 15px;
  resize: none; }

.form-item {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .form-item {
      margin-bottom: 25px; } }
  .form-item-title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    padding-left: 20px;
    width: 100%; }
  .form-item.validation-item.error .form-item-title {
    color: #f00;
    transition: all 0.2s; }
  .form-item.validation-item.error input,
  .form-item.validation-item.error textarea,
  .form-item.validation-item.error .form-item-file label,
  .form-item.validation-item.error .form-item-file-uploaded {
    border-color: #f00;
    transition: all 0.2s; }
  .form-item-file {
    cursor: pointer; }
    .form-item-file input {
      display: none; }
    .form-item-file-uploaded,
    .form-item-file label {
      background: #f0f0f0;
      border-radius: 25px;
      border: 1px dashed #c4c4c4;
      color: #04509b;
      font-size: 14px;
      font-weight: bold;
      line-height: 38px;
      margin-bottom: 0;
      outline: none;
      padding: 0 20px;
      text-align: center;
      width: 100%; }
      @media (min-width: 768px) {
        .form-item-file-uploaded,
        .form-item-file label {
          font-size: 16px;
          line-height: 48px; } }
      .form-item-file-uploaded span,
      .form-item-file label span {
        color: #676767;
        font-weight: normal; }
    .form-item-file label {
      cursor: pointer; }
    .form-item-file-uploaded {
      display: none;
      position: relative; }
    .form-item-file-delete {
      border: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(../img/svg/close.svg); }

.form-select {
  position: relative; }
  .form-select-link {
    border-radius: 30px;
    border: 1px solid #04509b;
    color: #04509b;
    display: block;
    font-size: 20px;
    font-weight: bold;
    line-height: 38px;
    outline: none;
    overflow: hidden;
    padding: 0 35px 0 50px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: 0.4s ease-in-out;
    white-space: nowrap;
    width: 100%; }
    @media (min-width: 768px) {
      .form-select-link {
        font-size: 20px;
        font-weight: normal;
        line-height: 48px; } }
    .form-select-link:hover {
      text-decoration: none; }
    .form-select-link:focus {
      box-shadow: 0px 0px 0px 1px #04509b;
      text-decoration: none; }
    .form-select-link::after {
      content: '';
      border: solid #000;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: translateY(-50%) rotate(45deg);
      position: absolute;
      right: 26px;
      top: 50%;
      transition: transform 0.2s ease-in-out; }
    .form-select-link.__active {
      color: #000;
      font-weight: bold; }
    .form-select-link.__icon::before {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }
    .form-select-link.__icon.__location::before {
      background-image: url(../img/svg/location.svg); }
    .form-select-link.__icon.__calendar::before {
      background-image: url(../img/svg/calendar.svg); }
    .form-select-link.__icon.__training-type::before {
      background-image: url(../img/svg/training-type.svg); }
  .form-select-content {
    background-color: #fff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: block;
    left: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute !important;
    top: 45px;
    transition: opacity 0.4s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 2; }
    @media (min-width: 992px) {
      .form-select-content {
        top: 55px; } }
    .form-select-content .scroll-content {
      padding: 10px 0; }
  .form-select.__open .form-select-content {
    max-height: 340px;
    opacity: 1;
    overflow-y: auto;
    visibility: visible; }
  .form-select.__open .form-select-link::after {
    transform: translateY(-50%) rotate(-135deg); }
  .form-select-item {
    color: #000;
    display: block;
    font-size: 20px;
    padding: 4px 28px;
    transition: 0.4s linear; }
    .form-select-item.__current {
      color: #04509b;
      font-weight: bold; }
    .form-select-item:hover {
      background-color: #f5f5f5;
      text-decoration: none; }

.form-checkbox {
  display: none; }
  .form-checkbox.validation-item.error + .form-checkbox-label:before {
    background: rgba(255, 0, 0, 0.15);
    border-color: #f00;
    border-width: 2px;
    transition: all 0.2s; }
  .form-checkbox-item {
    padding-left: 20px; }
  .form-checkbox-label {
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    padding-left: 30px;
    transition: .2s linear;
    vertical-align: middle; }
    .form-checkbox-label::before {
      content: '';
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 10px 8px;
      border: 1px solid #898989;
      border-radius: 2px;
      left: 0;
      position: absolute;
      top: 5px;
      transition: all 0.2s linear;
      width: 16px;
      height: 16px; }
  .form-checkbox:checked + .form-checkbox-label:before {
    background-color: #04509b;
    background-image: url(../img/svg/check.svg);
    border: 2px solid #04509b; }

.form-radio {
  display: none; }
  .form-radio-label {
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0;
    padding-left: 30px;
    position: relative;
    transition: .2s linear;
    vertical-align: middle; }
    .form-radio-label::before {
      background-color: transparent;
      border-radius: 100%;
      border: 2px solid #898989;
      content: '';
      height: 19px;
      left: 0;
      position: absolute;
      top: 4px;
      transition: all 0.2s linear;
      width: 19px; }
  .form-radio:checked + .form-radio-label {
    font-weight: bold; }
    .form-radio:checked + .form-radio-label::before {
      border: 6px solid #04509b; }

.breadcrumbs {
  color: #000;
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin: 40px auto;
  position: relative; }
  @media (min-width: 992px) {
    .breadcrumbs {
      margin-bottom: 40px;
      margin-top: 20px; } }
  .breadcrumbs::after {
    content: '';
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, #fff 100%);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px; }
    @media (min-width: 768px) {
      .breadcrumbs::after {
        width: 60px; } }
  .breadcrumbs-container {
    margin-top: 0;
    overflow-x: auto;
    padding-left: 0;
    white-space: nowrap;
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
    margin-bottom: -14px;
    /* Hide scrollbar for Chrome, Safari and Opera */ }
    .breadcrumbs-container::-webkit-scrollbar {
      display: none; }
  .breadcrumbs li {
    display: inline-block;
    position: relative; }
    .breadcrumbs li:not(:last-child) {
      padding-right: 20px; }
      .breadcrumbs li:not(:last-child)::after {
        color: #898989;
        content: '/';
        position: absolute;
        right: 6px;
        top: 0; }
    .breadcrumbs li a {
      color: #898989; }
  .breadcrumbs + section {
    padding-top: 0; }

.intro {
  padding: 0; }
  .intro-slider-slide {
    height: 360px;
    position: relative;
    padding: 40px 30px 50px;
    text-align: center; }
    @media (min-width: 576px) {
      .intro-slider-slide {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 500px; } }
    @media (min-width: 768px) {
      .intro-slider-slide {
        height: 640px; } }
    @media (min-width: 992px) {
      .intro-slider-slide {
        padding-left: 100px;
        padding-right: 100px; } }
    .intro-slider-slide-title {
      font-weight: bold;
      font-size: 25px;
      line-height: 28px;
      margin-bottom: 20px;
      text-align: left;
      text-shadow: 0 0 2px rgba(255, 255, 255, 0.8); }
      @media (min-width: 768px) {
        .intro-slider-slide-title {
          font-size: 50px;
          line-height: 60px;
          max-width: 70%; } }
      .intro-slider-slide-title span {
        display: block; }
    .intro-slider-slide-info {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 50px;
      text-align: left; }
      @media (min-width: 768px) {
        .intro-slider-slide-info {
          margin-bottom: 30px; } }
    .intro-slider-slide-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 8px;
      object-fit: cover; }

.digits {
  background: #ededed; }
  .digits-item {
    margin-bottom: 12px; }
    @media (min-width: 992px) {
      .digits-item {
        margin-bottom: 18px; } }
    .digits-item-container {
      align-items: center;
      background: #fff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      padding: 25px;
      margin-bottom: 12px;
      height: 100%; }
      @media (min-width: 992px) {
        .digits-item-container {
          margin-bottom: 18px;
          padding: 40px 45px 25px 45px; } }
    .digits-item-counter {
      font-size: 70px;
      font-weight: bold;
      color: #04509b;
      line-height: 1;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .digits-item-counter {
          font-size: 90px; } }
    .digits-item-text {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.3;
      text-align: center;
      margin-bottom: 0; }
      @media (min-width: 992px) {
        .digits-item-text {
          font-size: 20px; } }

@media (min-width: 992px) {
  .our_programs h2 {
    margin-bottom: 105px; } }

@media (min-width: 992px) {
  .our_programs .banner {
    margin-top: 55px; } }

.our_programs-item {
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .our_programs-item {
      margin-bottom: 16px; } }
  .our_programs-item:nth-child(odd) {
    padding-right: 5px; }
    @media (min-width: 768px) {
      .our_programs-item:nth-child(odd) {
        padding-right: 10px; } }
  .our_programs-item:nth-child(even) {
    padding-left: 5px; }
    @media (min-width: 768px) {
      .our_programs-item:nth-child(even) {
        padding-left: 10px; } }
  .our_programs-item-container {
    align-items: center;
    background: #fff;
    border-radius: 15px;
    border: 1px solid #04509b;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 33px 8px 25px;
    transition: all 0.2s;
    color: #000; }
    @media (min-width: 992px) {
      .our_programs-item-container {
        padding: 65px 60px 25px; } }
    .our_programs-item-container:hover, .our_programs-item-container:focus, .our_programs-item-container:active {
      box-shadow: 0px 0px 20px rgba(34, 4, 119, 0.47);
      background: #04509b;
      text-decoration: none; }
      .our_programs-item-container:hover svg path[stroke], .our_programs-item-container:focus svg path[stroke], .our_programs-item-container:active svg path[stroke] {
        stroke: #97c23c; }
      .our_programs-item-container:hover svg path[fill], .our_programs-item-container:focus svg path[fill], .our_programs-item-container:active svg path[fill] {
        fill: #97c23c; }
      .our_programs-item-container:hover .our_programs-item-text, .our_programs-item-container:focus .our_programs-item-text, .our_programs-item-container:active .our_programs-item-text {
        color: #fff; }
  .our_programs-item svg {
    margin-bottom: 15px; }
    @media (min-width: 992px) {
      .our_programs-item svg {
        margin-bottom: 35px; } }
  .our_programs-item-text {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center; }
    @media (min-width: 992px) {
      .our_programs-item-text {
        font-size: 20px; } }

.consultation {
  background: #04509b;
  color: #fff;
  text-align: center; }
  .consultation-form-title {
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    margin: 0 auto 30px;
    max-width: 935px; }
    @media (min-width: 768px) {
      .consultation-form-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px; } }
  .consultation-form-inputs {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto; }
    @media (min-width: 576px) {
      .consultation-form-inputs {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: none; } }
    .consultation-form-inputs input {
      background: transparent;
      border-color: #fff;
      color: #fff;
      width: 100%; }
      @media (min-width: 576px) {
        .consultation-form-inputs input {
          width: auto; } }
      .consultation-form-inputs input::placeholder {
        color: #fff; }
    .consultation-form-inputs button {
      margin-top: 20px;
      width: 100%; }
      @media (min-width: 576px) {
        .consultation-form-inputs button {
          margin-top: 0;
          margin-left: 20px;
          width: auto; } }

.partners {
  background: #f5f5f5; }
  .partners-logos {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .partners-logos {
        justify-content: center; } }
    .partners-logos img {
      align-self: center;
      border-radius: 3px;
      width: calc(50% - 5px);
      margin-bottom: 10px;
      max-width: 190px; }
      @media all and (min-width: 530px) {
        .partners-logos img {
          width: calc(33.33333% - 10px); } }
      @media (min-width: 768px) {
        .partners-logos img {
          margin: 10px 5px; } }
      @media (min-width: 992px) {
        .partners-logos img {
          margin: 10px;
          width: auto; } }

.feedback {
  text-align: center; }
  @media (min-width: 768px) and (max-width: 1279.98px) {
    .feedback .container {
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: border-box; } }
  .feedback-slider {
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .feedback-slider {
        padding: 0 40px; } }
    .feedback-slider .slick-arrow {
      top: 32px; }
      @media (min-width: 992px) {
        .feedback-slider .slick-arrow {
          top: 25px; } }
  .feedback-slide.feedbacks-item {
    margin-bottom: 0; }

.certificates {
  text-align: center; }
  @media (min-width: 768px) {
    .certificates .section-title {
      margin-bottom: 100px; } }
  .certificates-item {
    margin: 0 auto 40px;
    max-width: 433px; }
    @media (min-width: 992px) {
      .certificates-item {
        margin-bottom: 0; } }
    .certificates-item-image {
      margin-bottom: 10px; }
      @media (min-width: 768px) {
        .certificates-item-image {
          margin-bottom: 30px; } }
    .certificates-item-description {
      font-size: 16px;
      line-height: 21px; }
      @media (min-width: 768px) {
        .certificates-item-description {
          font-size: 20px;
          line-height: 26px; } }

.afu-seminars-slider {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .afu-seminars-slider {
      margin-bottom: 80px; } }

.afu-seminars-slide {
  display: block;
  position: relative;
  padding-bottom: 100%; }
  .afu-seminars-slide img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0; }

.afu-seminars-text {
  max-width: 820px;
  margin: 0 auto;
  position: relative; }
  .afu-seminars-text p {
    font-size: 18px;
    margin-bottom: 20px; }

.expandable {
  position: relative; }
  .expandable-arrow {
    border: none;
    display: block;
    margin: 55px auto 0;
    position: relative;
    height: 30px;
    width: 30px;
    background: #04509b;
    padding: 0;
    border-radius: 50%;
    animation: infoExpandBounce 1.6s ease-in-out infinite;
    transition: all 0.4s; }
    .expandable-arrow::before, .expandable-arrow::after {
      border-bottom: 11px solid #fff;
      box-sizing: content-box;
      content: '';
      display: block;
      height: 9px;
      left: 14px;
      position: absolute;
      top: 10px;
      transition: all 0.4s linear;
      width: 2px; }
    .expandable-arrow::before {
      transform: rotate(-135deg); }
    .expandable-arrow::after {
      transform: rotate(135deg); }
    .expandable-arrow.__active {
      animation: none;
      transform: rotate(180deg); }

@keyframes infoExpandBounce {
  0% {
    top: 10px; }
  50% {
    top: 20px; }
  100% {
    top: 10px; } }

.contacts-item {
  margin-bottom: 60px; }
  @media (min-width: 992px) {
    .contacts-item {
      margin-bottom: 70px; } }

.contacts-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 25px;
  text-align: left; }
  @media (min-width: 992px) {
    .contacts-title {
      font-size: 35px;
      line-height: 60px; } }

.contacts-location {
  margin-bottom: 15px; }
  .contacts-location-city {
    font-size: 25px;
    font-weight: bold;
    line-height: 60px;
    margin-bottom: 0; }
    @media (min-width: 992px) {
      .contacts-location-city {
        margin-left: 50px; } }
  .contacts-location-link {
    color: #000;
    display: block;
    font-size: 18px;
    line-height: 30px; }
    @media (min-width: 992px) {
      .contacts-location-link {
        margin-left: 50px; } }
    .contacts-location-link:hover, .contacts-location-link:focus, .contacts-location-link:active {
      color: #04509b; }

@media (min-width: 992px) {
  .contacts-map {
    padding-top: 45px; } }

.contacts-map iframe {
  max-width: 100%; }
  @media (max-width: 767.98px) {
    .contacts-map iframe {
      height: 400px;
      margin-top: 20px; } }

.blog.__preview .blog-card {
  margin-bottom: 0; }
  @media all and (max-width: 576px) {
    .blog.__preview .blog-card:nth-of-type(n + 2) {
      display: none; } }
  @media all and (max-width: 768px) {
    .blog.__preview .blog-card:nth-of-type(n + 3) {
      display: none; } }

.blog .page-title {
  text-align: center; }

@media (min-width: 768px) {
  .blog-container {
    position: relative; } }

.blog-tabs {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around; }
  @media (min-width: 768px) {
    .blog-tabs {
      justify-content: flex-start;
      padding-left: 20px; } }
  .blog-tabs-item {
    color: #000;
    font-size: 18px;
    font-weight: bold;
    display: block;
    position: relative;
    padding-bottom: 5px; }
    @media (min-width: 768px) {
      .blog-tabs-item {
        margin-right: 50px; } }
    .blog-tabs-item::after {
      background: #04509b;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transform: scaleX(0);
      transition: all 0.4s;
      visibility: hidden;
      width: 100%; }
    .blog-tabs-item.active {
      color: #04509b; }
      .blog-tabs-item.active::after {
        visibility: visible;
        transform: scaleX(1); }
    .blog-tabs-item:hover, .blog-tabs-item:focus, .blog-tabs-item:active {
      text-decoration: none; }
  .blog-tabs-content > .blog-tabs-content-item {
    display: none; }
  .blog-tabs-content > .active {
    display: block; }

.blog-card {
  cursor: pointer;
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .blog-card {
      margin-bottom: 120px; } }
  .blog-card-body {
    border-radius: 15px;
    padding-bottom: 10px;
    transition: all 0.2s; }
    .blog-card-body:hover {
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25); }
      .blog-card-body:hover .blog-item-image img {
        transform: scale(1.1); }
  .blog-card-image {
    height: 248px;
    margin-bottom: 15px;
    border-radius: 15px;
    overflow: hidden;
    position: relative; }
    @media (min-width: 992px) {
      .blog-card-image {
        margin-bottom: 22px;
        height: 320px; } }
    @media (min-width: 1280px) {
      .blog-card-image {
        height: 450px; } }
    .blog-card-image img {
      border-radius: 15px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.4s; }
  .blog-card-date {
    color: rgba(0, 0, 0, 0.6);
    font-size: 15px;
    margin-bottom: 8px;
    padding-left: 10px; }
    @media (min-width: 992px) {
      .blog-card-date {
        padding-left: 30px; } }
  .blog-card-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 5px;
    padding: 0 10px;
    height: 52px;
    overflow: hidden; }
    @media (min-width: 992px) {
      .blog-card-title {
        font-size: 24px;
        height: 52px;
        padding: 0 30px; } }
  .blog-card-text {
    font-size: 14px;
    line-height: 20px;
    padding: 0 10px; }
    @media (min-width: 992px) {
      .blog-card-text {
        font-size: 16px;
        padding: 0 30px; } }
  .blog-card-link {
    font-size: 18px;
    color: #04509b;
    display: inline-block;
    margin-bottom: 13px;
    padding: 0 10px; }
    @media (min-width: 992px) {
      .blog-card-link {
        padding: 0 30px; } }
    .blog-card-link:hover, .blog-card-link:focus, .blog-card-link:active {
      text-decoration: underline; }
  .blog-card-hash_tags {
    border-top: 1px solid #c4c4c4;
    font-size: 14px;
    padding: 18px 10px 0; }
    @media (min-width: 992px) {
      .blog-card-hash_tags {
        padding-left: 30px;
        padding-right: 30px; } }
    .blog-card-hash_tags a {
      color: #474774;
      margin-right: 9px; }
      .blog-card-hash_tags a:hover, .blog-card-hash_tags a:focus, .blog-card-hash_tags a:active {
        text-decoration: underline; }

.blog-link_posts {
  margin-top: 45px;
  text-align: center; }
  @media (min-width: 768px) {
    .blog-link_posts {
      margin-top: 0;
      position: absolute;
      right: 0;
      top: -3px; } }
  @media (min-width: 768px) {
    .blog-link_posts a {
      height: 35px;
      line-height: 35px; } }
  @media (min-width: 992px) {
    .blog-link_posts a {
      height: 40px;
      line-height: 40px; } }

@media (min-width: 992px) {
  .blog-post-section {
    padding-top: 50px; } }

.blog-post-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  text-align: left; }
  @media (min-width: 992px) {
    .blog-post-title {
      font-weight: bold;
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 15px; } }

.blog-post-date {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 0;
  text-transform: lowercase;
  opacity: .6; }
  @media (min-width: 992px) {
    .blog-post-date {
      font-size: 16px; } }

@media (max-width: 991.98px) {
  .blog-post .social-share {
    margin: 10px auto 30px; } }

.schedule .page-title {
  text-align: center; }

.schedule-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .schedule-title {
      font-size: 35px;
      line-height: 45px;
      margin-bottom: 55px; } }

.schedule-filters {
  margin-bottom: 30px; }
  @media (min-width: 992px) {
    .schedule-filters {
      margin-bottom: 50px; } }
  .schedule-filters-btn {
    width: 100%; }

.schedule-trainings-item {
  background: #f0f0f0;
  border-radius: 5px;
  margin: 0 auto 15px;
  padding: 22px 5px 32px;
  transition: all 0.4s; }
  @media (min-width: 576px) {
    .schedule-trainings-item {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 992px) {
    .schedule-trainings-item {
      background: #f5f5f5;
      border-radius: 10px;
      margin-bottom: 28px;
      padding-left: 0; } }
  @media (min-width: 1280px) {
    .schedule-trainings-item {
      padding: 30px 50px 30px 10px; } }
  .schedule-trainings-item:hover {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.25); }
    @media (min-width: 992px) {
      .schedule-trainings-item:hover .schedule-trainings-buttons {
        opacity: 1;
        visibility: visible; } }

.schedule-trainings-date {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .schedule-trainings-date {
      font-size: 25px;
      line-height: 35px; } }
  @media (min-width: 992px) {
    .schedule-trainings-date {
      margin-bottom: 25px;
      padding-left: 25px; } }
  @media (min-width: 1280px) {
    .schedule-trainings-date {
      font-size: 30px;
      line-height: 45px;
      padding-left: 40px; } }
  .schedule-trainings-date span:last-child {
    margin-right: 0; }
  @media (min-width: 992px) {
    .schedule-trainings-date span {
      margin-right: 0; } }

.schedule-trainings-city {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 0;
  text-align: center; }
  @media (min-width: 992px) {
    .schedule-trainings-city {
      display: block;
      padding-left: 25px;
      text-align: left; } }
  @media (min-width: 1280px) {
    .schedule-trainings-city {
      padding-left: 40px; } }

.schedule-trainings-staff {
  margin-bottom: 22px;
  margin-top: 20px;
  position: relative; }
  .schedule-trainings-staff[data-quantity="0"] {
    margin-bottom: 0; }
    .schedule-trainings-staff[data-quantity="0"]::after {
      display: none; }
  .schedule-trainings-staff::after {
    content: "+" attr(data-quantity);
    align-items: center;
    border-radius: 50%;
    border: 1px solid;
    background: #fff;
    color: #000;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    justify-content: center;
    position: relative;
    top: -15px;
    margin-bottom: -35px;
    width: 48px; }
  @media (min-width: 992px) {
    .schedule-trainings-staff {
      margin-top: 0; }
      .schedule-trainings-staff::after {
        display: none; } }
  .schedule-trainings-staff-item {
    align-items: center;
    display: flex;
    position: relative; }
    .schedule-trainings-staff-item:nth-child(n + 2) {
      display: none; }
      @media (min-width: 992px) {
        .schedule-trainings-staff-item:nth-child(n + 2) {
          display: flex;
          position: relative;
          top: -15px;
          margin-bottom: -15px; } }
      @media (min-width: 1280px) {
        .schedule-trainings-staff-item:nth-child(n + 2) {
          top: -20px;
          margin-bottom: -20px; } }
    .schedule-trainings-staff-item a {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
  .schedule-trainings-staff-image {
    border-radius: 50%;
    height: 48px;
    width: 48px;
    margin-right: 12px;
    position: relative;
    z-index: 1; }
    @media (min-width: 992px) {
      .schedule-trainings-staff-image {
        border: 2px solid #fff;
        height: 65px;
        width: 65px; } }
    @media (min-width: 1280px) {
      .schedule-trainings-staff-image {
        height: 85px;
        width: 85px;
        margin-right: 20px; } }
  .schedule-trainings-staff-name {
    font-weight: bold;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 0; }
    @media all and (max-width: 991px) {
      .schedule-trainings-staff-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px; } }
  .schedule-trainings-staff-position {
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 0;
    opacity: .6; }

.schedule-trainings-title {
  color: #000;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 30px;
  margin-bottom: 15px; }
  @media all and (max-width: 991px) {
    .schedule-trainings-title {
      font-weight: bold;
      font-size: 22px;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 5px;
      line-height: 26px; } }
  @media (min-width: 992px) {
    .schedule-trainings-title {
      margin-bottom: 25px;
      margin-top: 0; } }

.schedule-trainings-description {
  display: none; }
  @media (min-width: 992px) {
    .schedule-trainings-description {
      opacity: .8;
      display: block;
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 20px; } }

@media (min-width: 1280px) {
  .schedule-trainings-bottom {
    align-items: center;
    display: flex;
    justify-content: space-between; } }

.schedule-trainings-cost {
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .schedule-trainings-cost {
      font-size: 20px; } }
  @media (min-width: 1280px) {
    .schedule-trainings-cost {
      margin-bottom: 0; } }

.schedule-trainings-buttons {
  display: flex;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .schedule-trainings-buttons {
      display: block;
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s; } }
  .schedule-trainings-buttons .btn {
    height: 40px;
    line-height: 40px;
    flex: 0 0 calc(50% - 5px);
    padding-left: 5px;
    padding-right: 5px; }
    @media (min-width: 992px) {
      .schedule-trainings-buttons .btn {
        font-size: 18px;
        flex: 0;
        margin-right: 25px;
        width: 160px; }
        .schedule-trainings-buttons .btn:last-child {
          margin-right: 0; } }

.schedule-trainings-all {
  margin-top: 35px;
  text-align: center; }
  @media (min-width: 768px) {
    .schedule-trainings-all {
      margin-top: 85px; } }

.schedule .filters_mobile-show {
  align-items: center;
  background-image: url(../img/svg/filter-btn-bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 1; }
  @media (min-width: 992px) {
    .schedule .filters_mobile-show {
      display: none; } }
  .schedule .filters_mobile-show::before {
    background: #04509b;
    bottom: 0;
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    width: 100%; }
  .schedule .filters_mobile-show-btn {
    border: none;
    color: #fff;
    background: transparent;
    font-weight: bold;
    font-size: 20px;
    line-height: 50px;
    height: 50px;
    height: 50px;
    width: auto;
    position: relative;
    padding: 0 0 0 25px;
    outline: none; }
    .schedule .filters_mobile-show-btn::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 14px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(../img/svg/icon-filter.svg); }

.schedule .filters_mobile-actions {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1051;
  background-color: #fff;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  outline: none; }
  .schedule .filters_mobile-actions:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 12px;
    bottom: 0;
    background: #04509b;
    display: block;
    left: 0; }
  .schedule .filters_mobile-actions-container {
    display: flex; }
  .schedule .filters_mobile-actions-cancel {
    background: #fff;
    border: 0;
    width: 50%;
    font-size: 20px;
    line-height: 38px;
    height: 38px;
    border-radius: 25px;
    position: relative;
    z-index: 3;
    outline: none; }
  .schedule .filters_mobile-actions-apply {
    background-color: #04509b;
    color: #fff;
    border: 0;
    width: 50%;
    font-size: 20px;
    font-weight: bold;
    line-height: 38px;
    height: 38px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    position: relative;
    padding: 0; }
    .schedule .filters_mobile-actions-apply::before {
      content: '';
      display: block;
      background: #04509b;
      position: absolute;
      bottom: 0;
      left: -10px;
      height: 12px;
      width: 12px;
      z-index: 1; }
    .schedule .filters_mobile-actions-apply::after {
      content: '';
      display: block;
      background: #04509b;
      position: absolute;
      bottom: 0;
      right: -10px;
      height: 12px;
      width: 12px;
      z-index: 1; }
    .schedule .filters_mobile-actions-apply span {
      display: block;
      width: 100%;
      height: 100%;
      position: relative; }
      .schedule .filters_mobile-actions-apply span::before {
        content: '';
        display: block;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: -26px;
        height: 26px;
        width: 26px;
        z-index: 2;
        border-radius: 50%; }
      .schedule .filters_mobile-actions-apply span::after {
        content: '';
        display: block;
        background: #fff;
        position: absolute;
        bottom: 0;
        right: -26px;
        height: 26px;
        width: 26px;
        z-index: 2;
        border-radius: 50%; }

@media (min-width: 992px) {
  .schedule.__short h2 {
    margin-bottom: 65px; } }

.schedule.__short .schedule-filters {
  display: flex;
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .schedule.__short .schedule-filters {
      padding-bottom: 32px;
      border-bottom: 1px solid #04509b; } }
  .schedule.__short .schedule-filters-form {
    align-items: center;
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-filters-form {
        flex-direction: row; } }
  .schedule.__short .schedule-filters-item {
    width: 290px; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-filters-item {
        width: 295px; } }
    .schedule.__short .schedule-filters-item:nth-child(n + 2) {
      margin-top: 20px; }
      @media (min-width: 768px) {
        .schedule.__short .schedule-filters-item:nth-child(n + 2) {
          margin-top: 0;
          margin-left: 20px; } }
  .schedule.__short .schedule-filters-download {
    display: none; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-filters-download {
        display: inline-block;
        text-align: right; } }

.schedule.__short .schedule-info {
  align-items: center;
  margin-bottom: 24px; }
  @media (min-width: 768px) {
    .schedule.__short .schedule-info {
      margin-bottom: 15px; } }
  .schedule.__short .schedule-info-title {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
    text-align: center; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-info-title {
        margin-bottom: 0;
        text-align: left; } }
    @media (min-width: 992px) {
      .schedule.__short .schedule-info-title {
        font-size: 28px;
        line-height: 36px; } }
  .schedule.__short .schedule-info-download {
    padding-right: 20px;
    position: relative; }
    .schedule.__short .schedule-info-download::before {
      content: '';
      display: block;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 14px;
      background-repeat: no-repeat;
      background-image: url(../img/svg/download.svg); }
    @media (min-width: 768px) {
      .schedule.__short .schedule-info-download {
        display: none; } }
  .schedule.__short .schedule-info-additional {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-info-additional {
        justify-content: flex-end; } }
  .schedule.__short .schedule-info-contacts {
    display: flex;
    list-style: none;
    padding-left: 0;
    justify-content: flex-end;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-info-contacts {
        text-align: right; } }
    .schedule.__short .schedule-info-contacts a {
      color: #000;
      font-size: 0;
      line-height: 0;
      display: flex;
      align-items: center;
      transition: all 0.4s; }
      .schedule.__short .schedule-info-contacts a:hover, .schedule.__short .schedule-info-contacts a:focus, .schedule.__short .schedule-info-contacts a:active {
        color: #04509b; }
      @media (max-width: 767.98px) {
        .schedule.__short .schedule-info-contacts a {
          background: #E7E7E7;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          justify-content: center; }
          .schedule.__short .schedule-info-contacts a:hover, .schedule.__short .schedule-info-contacts a:focus, .schedule.__short .schedule-info-contacts a:active {
            background: #04509b; }
            .schedule.__short .schedule-info-contacts a:hover.__phone svg, .schedule.__short .schedule-info-contacts a:focus.__phone svg, .schedule.__short .schedule-info-contacts a:active.__phone svg {
              fill: #fff; }
            .schedule.__short .schedule-info-contacts a:hover.__email svg, .schedule.__short .schedule-info-contacts a:focus.__email svg, .schedule.__short .schedule-info-contacts a:active.__email svg {
              stroke: #fff; } }
      @media (min-width: 768px) {
        .schedule.__short .schedule-info-contacts a {
          font-size: 16px;
          line-height: 23px;
          font-weight: bold; } }
      @media (min-width: 992px) {
        .schedule.__short .schedule-info-contacts a {
          font-size: 18px; } }
      .schedule.__short .schedule-info-contacts a:first-child {
        margin-right: 35px; }
        @media (min-width: 768px) {
          .schedule.__short .schedule-info-contacts a:first-child {
            margin-right: 20px; } }
        @media (min-width: 992px) {
          .schedule.__short .schedule-info-contacts a:first-child {
            margin-right: 60px; } }
      .schedule.__short .schedule-info-contacts a svg {
        opacity: .7; }
        @media (min-width: 768px) {
          .schedule.__short .schedule-info-contacts a svg {
            margin-right: 5px; } }
        @media (min-width: 992px) {
          .schedule.__short .schedule-info-contacts a svg {
            margin-right: 15px; } }
      .schedule.__short .schedule-info-contacts a.__email svg {
        stroke: #000; }

.schedule.__short .schedule-table {
  margin-bottom: 28px; }
  @media (min-width: 768px) {
    .schedule.__short .schedule-table {
      margin-bottom: 42px; } }
  .schedule.__short .schedule-table-item {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 17px 13px;
    position: relative;
    display: block;
    transition: all 0.13333s ease-in-out;
    cursor: pointer; }
    @media (min-width: 768px) {
      .schedule.__short .schedule-table-item {
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        grid-column-gap: 10px; } }
    @media (min-width: 992px) {
      .schedule.__short .schedule-table-item {
        grid-template-columns: 1.5fr 2fr;
        padding: 20px 25px; } }
    @media (min-width: 1280px) {
      .schedule.__short .schedule-table-item {
        grid-template-columns: 1fr 2fr;
        padding: 25px 42px 22px 35px; } }
    .schedule.__short .schedule-table-item:nth-child(n + 2) {
      margin-top: 10px; }
      @media (min-width: 768px) {
        .schedule.__short .schedule-table-item:nth-child(n + 2) {
          margin-top: 5px; } }
    .schedule.__short .schedule-table-item:hover {
      box-shadow: 0 -10px 0 #f5f5f5, 0 10px 0 #f5f5f5, 0 -10px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.25), 0 0px 10px rgba(0, 0, 0, 0.25);
      z-index: 1; }
      .schedule.__short .schedule-table-item:hover .btn.__outline {
        background: #04509b;
        color: #fff; }
    .schedule.__short .schedule-table-item-info {
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
      grid-column-gap: 12px;
      align-items: center; }
      @media (min-width: 992px) {
        .schedule.__short .schedule-table-item-info {
          margin-bottom: 0; } }
    .schedule.__short .schedule-table-item-date {
      font-size: 18px;
      font-weight: bold;
      line-height: 23px; }
      @media (min-width: 768px) {
        .schedule.__short .schedule-table-item-date {
          font-size: 20px;
          line-height: 28px; } }
    .schedule.__short .schedule-table-item-image {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      position: relative; }
      @media (min-width: 576px) {
        .schedule.__short .schedule-table-item-image {
          flex-direction: row;
          padding-bottom: 0; } }
      .schedule.__short .schedule-table-item-image[data-quantity="0"] {
        padding-bottom: 0; }
        .schedule.__short .schedule-table-item-image[data-quantity="0"]::after {
          display: none; }
      .schedule.__short .schedule-table-item-image::after {
        content: "+" attr(data-quantity);
        align-items: center;
        border-radius: 50%;
        border: 1px solid;
        background: #fff;
        color: #000;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        height: 48px;
        justify-content: center;
        position: relative;
        top: -15px;
        margin-bottom: -35px;
        width: 48px;
        flex: 0 0 48px; }
        @media (min-width: 576px) {
          .schedule.__short .schedule-table-item-image::after {
            left: -15px;
            top: auto; } }
      .schedule.__short .schedule-table-item-image img {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        position: relative; }
        .schedule.__short .schedule-table-item-image img:first-child {
          z-index: 1; }
        .schedule.__short .schedule-table-item-image img:nth-child(2) {
          top: -10px;
          z-index: 0; }
          @media (min-width: 576px) {
            .schedule.__short .schedule-table-item-image img:nth-child(2) {
              top: auto;
              left: -10px; } }
    .schedule.__short .schedule-table-item-trainer {
      font-size: 18px;
      font-weight: bold;
      line-height: 28px; }
      @media all and (max-width: 991px) {
        .schedule.__short .schedule-table-item-trainer {
          font-weight: 400;
          font-size: 16px; } }
      .schedule.__short .schedule-table-item-trainer span {
        display: block; }
    @media (min-width: 992px) {
      .schedule.__short .schedule-table-item-course {
        display: flex;
        align-items: center;
        justify-content: space-between; } }
    .schedule.__short .schedule-table-item-course p {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 0; }
      @media all and (max-width: 991px) {
        .schedule.__short .schedule-table-item-course p {
          font-weight: bold;
          font-size: 22px;
          display: inline-block;
          margin-top: 15px;
          margin-bottom: 5px;
          line-height: 24px; } }
      @media (min-width: 768px) {
        .schedule.__short .schedule-table-item-course p {
          font-size: 20px;
          line-height: 28px;
          padding-right: 40px; } }
    .schedule.__short .schedule-table-item-course .btn {
      display: none; }
      @media (min-width: 992px) {
        .schedule.__short .schedule-table-item-course .btn {
          display: inline-block;
          height: 40px;
          line-height: 40px; } }

.schedule.__short .schedule-all {
  text-align: center; }

@media (min-width: 992px) {
  .training-section {
    padding-bottom: 10px; } }

.training-container {
  margin-bottom: 45px;
  position: relative; }
  .training-container::after {
    content: '';
    display: block;
    left: 0;
    width: 100%;
    bottom: -45px;
    height: 1px;
    background: #04509b;
    position: absolute; }
    @media (min-width: 992px) {
      .training-container::after {
        bottom: -120px; } }
  @media (min-width: 992px) {
    .training-container {
      margin-bottom: 120px; } }
  .training-container .page-title {
    font-weight: bold;
    font-size: 25px;
    line-height: 28px;
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .training-container .page-title {
        font-size: 40px;
        line-height: 53px; } }
    @media (min-width: 1280px) {
      .training-container .page-title {
        max-width: 715px; } }
  @media (min-width: 1280px) {
    .training-container .social-share {
      margin-left: 0;
      max-width: 715px; } }
  @media (min-width: 1280px) {
    .training-container-side {
      position: absolute;
      right: 0;
      width: 400px;
      top: 0;
      margin-top: 20px; } }
  @media (max-width: 1279.98px) {
    .training-container-side .side-banner {
      display: none; } }

.training-closest {
  padding: 28px 0 34px;
  text-align: center; }
  @media (min-width: 992px) {
    .training-closest {
      border: 1px solid #848484;
      border-radius: 5px;
      padding: 28px 25px 34px; } }
  .training-closest-title {
    display: none; }
    @media (min-width: 992px) {
      .training-closest-title {
        display: block;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 22px;
        text-align: center; } }
  .training-closest-info {
    text-align: left; }
    @media (min-width: 768px) {
      .training-closest-info {
        text-align: center; } }
    @media (min-width: 992px) {
      .training-closest-info {
        font-size: 18px;
        line-height: 23px; } }
    .training-closest-info-item {
      display: inline-block;
      padding-left: 30px;
      position: relative;
      margin-bottom: 30px; }
      @media (min-width: 992px) {
        .training-closest-info-item {
          margin-bottom: 0; } }
      .training-closest-info-item::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        height: 20px;
        width: 20px;
        opacity: .6; }
      @media (max-width: 1279.98px) {
        .training-closest-info-item.location {
          font-weight: bold; } }
      .training-closest-info-item.location::before {
        background-image: url(../img/svg/location.svg); }
      @media (max-width: 1279.98px) {
        .training-closest-info-item.dates {
          font-weight: bold; } }
      .training-closest-info-item.dates::before {
        background-image: url(../img/svg/calendar.svg); }
      .training-closest-info-item.costs {
        text-align: left; }
        @media (min-width: 992px) {
          .training-closest-info-item.costs {
            display: inline-block;
            margin-top: 30px;
            margin-bottom: 40px; } }
        .training-closest-info-item.costs::before {
          top: 2px;
          transform: none;
          background-image: url(../img/svg/cost.svg); }
        .training-closest-info-item.costs .costs-item {
          display: block;
          margin-bottom: 8px; }
          .training-closest-info-item.costs .costs-item.__old {
            position: relative; }
            .training-closest-info-item.costs .costs-item.__old::after {
              content: '';
              display: block;
              height: 2px;
              width: calc(100% + 8px);
              background: red;
              position: absolute;
              left: -4px;
              top: calc(50% - 1px); }
          .training-closest-info-item.costs .costs-item.__current {
            font-weight: bold; }
  .training-closest-staff {
    margin-bottom: 5px;
    padding: 0 5px; }
    .training-closest-staff-title {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 20px; }
      @media (min-width: 992px) {
        .training-closest-staff-title {
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 15px; } }
  .training-closest-methodists {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .training-closest-methodists-item {
      margin-bottom: 20px;
      padding: 0 5px;
      width: 33.3333%; }
      .training-closest-methodists-item a {
        align-items: center;
        display: inline-flex;
        color: #000;
        flex-direction: column;
        transition: all 0.2s; }
        .training-closest-methodists-item a:hover, .training-closest-methodists-item a:focus, .training-closest-methodists-item a:active {
          color: #04509b;
          text-decoration: none; }
          .training-closest-methodists-item a:hover img, .training-closest-methodists-item a:focus img, .training-closest-methodists-item a:active img {
            box-shadow: 0px 0px 6px #000; }
      .training-closest-methodists-item img {
        border-radius: 50%;
        height: 82px;
        width: 82px;
        margin-bottom: 15px;
        transition: all 0.4s; }
      .training-closest-methodists-item span {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.4; }
  @media (max-width: 991.98px) {
    .training-closest-bottom {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      z-index: 2;
      height: 50px; } }
  .training-closest-bottom-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 5px; }
    @media (max-width: 991.98px) {
      .training-closest-bottom-info {
        background: #fff;
        flex: 0 0 50%; } }
  .training-closest-bottom-places {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px; }
    @media (min-width: 992px) {
      .training-closest-bottom-places {
        font-size: 18px;
        font-weight: normal;
        line-height: 23px;
        margin-bottom: 15px; } }
  .training-closest-bottom-price {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px; }
    @media (min-width: 992px) {
      .training-closest-bottom-price {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    .training-closest-bottom .btn {
      background: #04509b;
      color: #fff;
      flex: 0 0 50%;
      margin-bottom: 0;
      border-radius: 0;
      height: 100%;
      padding: 0 10px; } }

@media (min-width: 1280px) {
  .training-content {
    max-width: 715px; } }

.training-content table td:first-child {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle; }
  @media (min-width: 992px) {
    .training-content table td:first-child {
      font-size: 18px; } }

.training h2 {
  font-size: 25px;
  line-height: 32px; }
  @media (min-width: 992px) {
    .training h2 {
      font-size: 35px;
      line-height: 45px; } }

@media (min-width: 992px) {
  .training .our_programs {
    padding-bottom: 160px; } }

.team .page-title {
  text-align: center; }

.team-item {
  border-radius: 5px;
  color: #000;
  display: block;
  margin-bottom: 10px;
  padding: 15px 3px 20px;
  text-align: center;
  transition: all 0.4s; }
  @media (min-width: 992px) {
    .team-item {
      padding: 30px 24px 50px;
      margin-bottom: 26px; } }
  .team-item:hover, .team-item:focus, .team-item:active {
    background: #fff;
    box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.15);
    color: #000;
    text-decoration: none; }
  .team-item-image {
    border-radius: 50%;
    height: 82px;
    margin-bottom: 20px;
    width: 82px; }
    @media (min-width: 992px) {
      .team-item-image {
        height: 246px;
        margin-bottom: 30px;
        width: 246px; } }
  .team-item-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 0;
    min-height: 44px; }
    @media (min-width: 992px) {
      .team-item-name {
        font-size: 22px;
        margin-bottom: 10px;
        min-height: auto; } }
  .team-item-position {
    font-size: 14px;
    line-height: 1.4;
    opacity: .6; }
    @media (min-width: 992px) {
      .team-item-position {
        font-size: 16px; } }

.methodist-content {
  position: relative; }
  @media (max-width: 991.98px) {
    .methodist-content-image {
      text-align: center;
      width: 50%; } }
  @media (min-width: 992px) {
    .methodist-content-image {
      position: absolute;
      left: 0;
      top: 0; } }
  .methodist-content-image img {
    border-radius: 50%;
    height: 82px;
    width: 82px; }
    @media (min-width: 768px) {
      .methodist-content-image img {
        height: 150px;
        width: 150px; } }
    @media (min-width: 992px) {
      .methodist-content-image img {
        height: 246px;
        width: 246px; } }
  @media (max-width: 991.98px) {
    .methodist-content-top {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      font-size: 14px; } }
  .methodist-content .methodist-content-name {
    margin-top: 0;
    margin-bottom: 15px; }
    @media (max-width: 767.98px) {
      .methodist-content .methodist-content-name {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .methodist-content .methodist-content-name {
        margin-bottom: 75px; } }
  @media (max-width: 991.98px) {
    .methodist-content-txt {
      margin-top: 40px; } }
  @media (min-width: 992px) {
    .methodist-content-txt {
      padding-left: 315px; } }
  @media (min-width: 992px) {
    .methodist-content-socials {
      position: absolute;
      top: 266px;
      left: 0;
      width: 246px; } }
  .methodist-content-socials .social {
    justify-content: flex-start; }
    @media (min-width: 992px) {
      .methodist-content-socials .social {
        justify-content: center; } }
    .methodist-content-socials .social-item {
      margin-bottom: 0;
      margin-right: 10px;
      padding-left: 0; }
      .methodist-content-socials .social-item::before {
        display: none; }

.page-404 {
  min-height: 100vh; }
  .page-404 section {
    background: #f0f6f8;
    padding: 80px 0;
    text-align: center; }
    @media (min-width: 992px) {
      .page-404 section {
        padding-top: 168px;
        padding-bottom: 110px; } }
  .page-404-image {
    margin-bottom: 20px; }
  .page-404 h1 {
    margin-bottom: 50px;
    text-align: center; }
    @media (min-width: 992px) {
      .page-404 h1 {
        font-size: 43px;
        line-height: 56px;
        margin-bottom: 80px; } }
  .page-404-link {
    padding-left: 70px;
    padding-right: 70px; }

.search-form {
  position: relative; }
  .search-form input {
    background: #fff;
    border-color: #898989;
    border-radius: 6px;
    color: #000;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    padding-right: 50px;
    text-align: left;
    width: 100%; }
    @media (min-width: 992px) {
      .search-form input {
        font-size: 18px; } }
  .search-form button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/svg/search.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    padding: 0; }
    .search-form button:hover, .search-form button:focus, .search-form button:active {
      background-color: transparent;
      opacity: .5; }

.search-title {
  border-bottom: 1px solid #c4c4c4;
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  margin-top: 25px;
  margin-bottom: 27px;
  padding-bottom: 15px; }
  @media (min-width: 992px) {
    .search-title {
      font-size: 35px;
      line-height: 45px;
      margin-top: 40px;
      margin-bottom: 32px;
      padding-bottom: 30px; } }

.search-results-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; }
  @media (min-width: 992px) {
    .search-results-item {
      flex-direction: row; } }
  .search-results-item-info {
    color: #04509b;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px; }
    @media (max-width: 991.98px) {
      .search-results-item-info {
        align-items: center;
        display: flex;
        flex-direction: row; } }
    @media (min-width: 992px) {
      .search-results-item-info {
        flex-shrink: 0;
        padding-right: 10px;
        width: 138px; } }
    .search-results-item-info p {
      margin-bottom: 10px; }
      @media (max-width: 991.98px) {
        .search-results-item-info p {
          flex: 0 0 50%; } }
  .search-results-item-title {
    color: #000;
    display: block;
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 10px; }
    @media (min-width: 992px) {
      .search-results-item-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px; } }
    .search-results-item-title:hover, .search-results-item-title:focus, .search-results-item-title:active {
      color: #04509b;
      text-decoration: none; }
  .search-results-item-preview {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 5px;
    opacity: .6; }
    @media (min-width: 992px) {
      .search-results-item-preview {
        font-size: 16px; } }
  .search-results-item-category {
    font-size: 12px;
    font-family: "HelveticaNeueCyr", sans-serif;
    line-height: 1; }

@media (min-width: 992px) {
  .feedbacks .page-title {
    margin-bottom: 100px;
    text-align: center; } }

.feedbacks-item {
  margin-bottom: 65px;
  padding: 0 10px;
  text-align: center; }
  @media (min-width: 992px) {
    .feedbacks-item {
      margin-bottom: 95px;
      padding: 0 20px; } }
  @media (min-width: 1280px) {
    .feedbacks-item {
      padding: 0 40px; } }
  .feedbacks-item-avatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: block;
    margin: 0 auto 16px; }
  .feedbacks-item-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .feedbacks-item-name {
        font-size: 20px;
        line-height: 1;
        margin-bottom: 5px; } }
  .feedbacks-item-club {
    font-size: 14px;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .feedbacks-item-club {
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 15px; } }
  .feedbacks-item .social {
    justify-content: center;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .feedbacks-item .social {
        margin-bottom: 30px; } }
    .feedbacks-item .social-item {
      margin-right: 5px; }
      .feedbacks-item .social-item:last-child {
        margin-right: 0; }
  .feedbacks-item-description {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 0;
    opacity: .7; }
    @media (min-width: 768px) {
      .feedbacks-item-description {
        font-size: 18px; } }

.feedbacks-more {
  display: block;
  margin: 0 auto;
  padding: 0 44px; }

.employees .page-title {
  margin-bottom: 15px;
  text-align: center; }
  @media (min-width: 992px) {
    .employees .page-title {
      text-align: left; } }

.employees .page-subtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center; }
  @media (min-width: 992px) {
    .employees .page-subtitle {
      font-size: 18px;
      line-height: 29px;
      text-align: left; } }

.employees-form {
  margin-top: 25px;
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    .employees-form {
      margin-top: 50px; } }
  .employees-form input {
    width: 100%; }
  .employees-form .form-item {
    margin-bottom: 26px; }
    @media (min-width: 992px) {
      .employees-form .form-item {
        margin-bottom: 0; } }
  .employees-form-input {
    border-color: #898989;
    color: #9E9E9E;
    font-size: 16px;
    font-weight: normal;
    padding-left: 50px;
    position: relative;
    background-position: 17px center;
    background-repeat: no-repeat;
    background-image: url(../img/svg/search.svg);
    transition: all 0.4s;
    text-align: left; }
    .employees-form-input:focus {
      box-shadow: 0 0 0 1px #898989; }
    .employees-form-input.validation-item.error:focus {
      box-shadow: 0 0 0 1px #f00; }
  @media (max-width: 991.98px) {
    .employees-form-example {
      display: none; } }
  @media (min-width: 992px) {
    .employees-form-example {
      color: #898989;
      font-size: 14px;
      line-height: 18px;
      margin-top: 8px;
      padding-left: 10px; } }
  .employees-form-submit {
    width: 100%; }

.employees-search-title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 60px; }
  @media (min-width: 992px) {
    .employees-search-title {
      font-size: 30px;
      margin-bottom: 30px; } }

.employees-search-item {
  padding: 12px 0 20px;
  border-bottom: 1px solid #c4c4c4; }
  .employees-search-item:first-of-type {
    border-top: 1px solid #c4c4c4; }
  .employees-search-item-date {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center; }
    @media (max-width: 767.98px) {
      .employees-search-item-date {
        margin-bottom: 10px; } }
    @media (min-width: 768px) {
      .employees-search-item-date {
        font-size: 16px; } }
  .employees-search-item-position {
    font-weight: bold;
    font-size: 18px;
    color: #000; }
    @media (max-width: 767.98px) {
      .employees-search-item-position {
        margin-bottom: 10px;
        text-align: right; } }
    @media (min-width: 768px) {
      .employees-search-item-position {
        font-size: 20px; } }
    .employees-search-item-position:hover, .employees-search-item-position:focus, .employees-search-item-position:active {
      text-decoration: underline; }
  .employees-search-item-city {
    font-weight: bold;
    font-size: 16px; }
    @media (min-width: 768px) {
      .employees-search-item-city {
        font-size: 20px;
        text-align: center; } }
  .employees-search-item-cost {
    font-weight: bold;
    font-size: 16px;
    text-align: right; }
    @media (min-width: 768px) {
      .employees-search-item-cost {
        font-size: 20px; } }
  .employees-search-item-description {
    color: #898989;
    font-size: 12px;
    line-height: 20px;
    margin-top: 20px; }
    @media (min-width: 768px) {
      .employees-search-item-description {
        font-size: 14px;
        line-height: 22px; } }

.employees-search .pagination {
  margin-top: 30px; }
  @media (min-width: 992px) {
    .employees-search .pagination {
      margin-top: 70px; } }

@media (max-width: 991.98px) {
  .employees-filters {
    display: none; } }

@media (min-width: 992px) {
  .employees-filters {
    padding-left: 20px;
    padding-top: 10px; } }

@media (min-width: 1280px) {
  .employees-filters {
    padding-left: 77px; } }

.employees-filters-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px; }
  @media (min-width: 992px) {
    .employees-filters-title {
      margin-bottom: 60px; } }

.employees-filters-selected {
  align-items: center;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .employees-filters-selected {
      margin-bottom: 14px; } }
  .employees-filters-selected-item {
    font-size: 12px;
    line-height: 210%;
    color: #474747;
    position: relative;
    background: rgba(107, 199, 42, 0.31);
    padding: 0 20px 0 9px;
    line-height: 21px;
    display: inline-flex;
    align-items: center;
    margin-right: 13px;
    border-radius: 10px;
    transition: all 0.4s;
    cursor: pointer;
    margin-bottom: 17px; }
    .employees-filters-selected-item::after {
      content: "";
      display: block;
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      height: 7px;
      width: 7px;
      background-image: url(../img/svg/close.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .employees-filters-selected-item:hover {
      background: rgba(107, 199, 42, 0.5); }
  .employees-filters-selected-reset {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 17px;
    color: #04509B; }

.employees-filters-block {
  margin-bottom: 30px; }
  .employees-filters-block-name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px; }
  .employees-filters-block-container .form-checkbox-label::before {
    top: 5px; }

.employees-post-form input,
.employees-post-form textarea {
  border-color: #c4c4c4;
  font-size: 16px;
  max-width: 573px;
  opacity: .5;
  width: 100%; }

.employees-post-form textarea {
  height: 165px; }

.employees-post-form .form-item-file {
  max-width: 573px; }

@media (min-width: 1280px) {
  .employees-post-form .row > .col-12:last-child {
    padding-left: 47px; } }

.employees-post-form-submit {
  display: block;
  margin: 45px auto 0;
  width: 190px; }

.work .page-title {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .work .page-title {
      margin-bottom: 35px; } }

.work .page-subtitle {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .work .page-subtitle {
      font-size: 18px;
      line-height: 29px;
      margin-bottom: 85px; } }

.work-option {
  border-bottom: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4; }
  @media (max-width: 991.98px) {
    .work-option {
      margin-bottom: 10px; } }
  @media (min-width: 576px) {
    .work-option {
      border: none; } }
  .work-option-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 27px;
    padding-top: 20px; }
    @media (min-width: 576px) {
      .work-option-container {
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        padding: 20px 30px 27px; } }
    @media (min-width: 1280px) {
      .work-option-container {
        padding: 35px 42px 45px 48px; } }
  .work-option-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left; }
    @media (min-width: 992px) {
      .work-option-title {
        font-size: 30px;
        margin-bottom: 15px; } }
  .work-option-description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px; }
    @media (min-width: 992px) {
      .work-option-description {
        margin-bottom: 45px; } }
  .work-option-links {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px; }
    .work-option-links .btn {
      padding: 0 10px;
      width: calc(50% - 5px);
      justify-content: center; }
      @media (min-width: 576px) {
        .work-option-links .btn {
          align-items: center;
          display: flex;
          justify-content: center;
          width: calc(50% - 13px); } }
      .work-option-links .btn i {
        display: none; }
        @media (min-width: 576px) {
          .work-option-links .btn i {
            display: block;
            margin-right: 10px; } }
        @media (min-width: 992px) {
          .work-option-links .btn i {
            display: none; } }
        @media (min-width: 1280px) {
          .work-option-links .btn i {
            display: block; } }
  .work-option-actual {
    border-top: 1px solid #04509b;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 25px; }
    .work-option-actual-title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 20px; }
    .work-option-actual-row {
      align-items: center;
      display: flex;
      font-size: 14px;
      margin-bottom: 10px; }
      @media (min-width: 768px) {
        .work-option-actual-row {
          margin-bottom: 15px; }
          .work-option-actual-row:last-of-type {
            margin-bottom: 40px; } }
    @media (min-width: 992px) {
      .work-option-actual-date {
        font-size: 16px; } }
    .work-option-actual-position {
      color: #000; }
    @media (min-width: 992px) {
      .work-option-actual-salary {
        font-size: 16px; } }
    .work-option-actual .btn {
      display: block;
      margin: 15px auto 0;
      max-width: 170px; }
      @media (min-width: 768px) {
        .work-option-actual .btn {
          margin-top: auto; } }

.resume .page-title {
  margin-bottom: 20px; }
  @media (min-width: 992px) {
    .resume .page-title {
      margin-bottom: 15px; } }

.resume-date {
  font-size: 16px;
  line-height: 21px; }
  @media (min-width: 992px) {
    .resume-date {
      margin-bottom: 15px; } }
  .resume-date span {
    font-weight: bold; }

.resume-rubrics {
  font-size: 16px;
  line-height: 21px;
  display: flex;
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .resume-rubrics {
      display: block; } }
  @media (min-width: 992px) {
    .resume-rubrics {
      margin-bottom: 55px; } }
  .resume-rubrics-title {
    margin-right: 5px; }
  .resume-rubrics-links {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .resume-rubrics-links {
        display: inline; } }
    .resume-rubrics-links a {
      text-decoration: underline; }
      .resume-rubrics-links a::after {
        content: ";"; }
      .resume-rubrics-links a:last-child {
        margin-right: 0; }
        .resume-rubrics-links a:last-child::after {
          display: none; }
      @media (min-width: 768px) {
        .resume-rubrics-links a {
          margin-right: 8px; } }
      .resume-rubrics-links a:hover, .resume-rubrics-links a:focus, .resume-rubrics-links a:active {
        text-decoration: none; }

@media (min-width: 1280px) {
  .resume-person > .resume-person-name {
    display: none; } }

.resume-person-name {
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 27px;
  text-align: left; }
  @media (min-width: 992px) {
    .resume-person-name {
      font-size: 35px;
      line-height: 45px; } }

@media (min-width: 992px) {
  .resume-person-main {
    display: flex;
    margin-bottom: 30px; } }

.resume-person-main-top {
  align-items: center;
  display: flex; }
  @media (max-width: 991.98px) {
    .resume-person-main-top {
      margin-bottom: 30px; } }
  @media (min-width: 992px) {
    .resume-person-main-top {
      flex-shrink: 0; } }
  @media (min-width: 1280px) {
    .resume-person-main-top {
      margin-right: 70px; } }
  @media (min-width: 992px) {
    .resume-person-main-top .resume-person-position {
      display: none; } }
  @media (min-width: 992px) {
    .resume-person-main-top .resume-person-salary {
      display: none; } }

@media (max-width: 1279.98px) {
  .resume-person-main-details .resume-person-name {
    display: none; } }

@media (max-width: 991.98px) {
  .resume-person-main-details .resume-person-position {
    display: none; } }

@media (max-width: 991.98px) {
  .resume-person-main-details .resume-person-salary {
    display: none; } }

.resume-person-position {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px; }
  @media (min-width: 992px) {
    .resume-person-position {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 10px; } }

.resume-person-image {
  height: 82px;
  width: 82px;
  border-radius: 50%;
  margin-right: 30px; }
  @media (min-width: 768px) {
    .resume-person-image {
      height: 130px;
      width: 130px; } }
  @media (min-width: 992px) {
    .resume-person-image {
      height: 180px;
      width: 180px; } }
  @media (min-width: 1280px) {
    .resume-person-image {
      height: 246px;
      width: 246px; } }

.resume-person-info {
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 25px; }
  @media (min-width: 992px) {
    .resume-person-info {
      margin-bottom: 0; } }
  @media (min-width: 1280px) {
    .resume-person-info-block:first-child {
      margin-right: 70px; } }
  .resume-person-info-item {
    margin-bottom: 2px; }
    .resume-person-info-item span {
      font-weight: bold; }
    .resume-person-info-item a {
      color: #000;
      font-weight: bold; }

.resume-person-salary {
  font-size: 20px;
  line-height: 26px; }
  @media (min-width: 992px) {
    .resume-person-salary {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 10px; } }

.resume-info-block {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .resume-info-block {
      margin-bottom: 45px; } }
  .resume-info-block:last-child {
    margin-bottom: 0; }
  .resume-info-block-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .resume-info-block-title {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 25px; } }
  .resume-info-block-text {
    font-size: 16px;
    line-height: 1.44;
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .resume-info-block-text {
        font-size: 20px; } }
    .resume-info-block-text:last-child {
      margin-bottom: 0; }
    .resume-info-block-text a {
      color: #000; }

.resume-add {
  background: #f5f5f5;
  border-radius: 5px;
  text-align: center;
  padding: 32px 0; }
  @media (min-width: 992px) {
    .resume-add {
      padding: 48px 0; } }
