//icons
.icon-add {
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../img/svg/icon-add-white.svg);
	height: 23px;
	width: 23px;
	display: inline-block;
}

.icon-search {
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../img/svg/search-white.svg);
	height: 23px;
	width: 23px;
	display: inline-block;
}


.page-title {
	font-size: 25px;
	font-weight: bold;
	line-height: 28px;
	text-align: left;
	margin-bottom: 30px;

	@include lg {
		font-size: 50px;
		line-height: 60px;
		margin-bottom: 65px;
	}
}

.scrollbar {
	&-track {
		//background: $c-lgrey-2 !important;
		//width: 14px !important;
	}

	&-thumb {
		border-radius: 5px;
		//width: 14px !important;
		//height: 31px !important;
		//background: $c-grey !important;
	}
}

.link-to-top {
	background: rgba($c-default, .6);
	border-radius: 50%;
	position: fixed;
	right: 10px;
	bottom: 50px;
	height: 50px;
	opacity: 0;
	visibility: hidden;
	width: 50px;
	transition: $time ease-in-out;
	z-index: 999;

	&::before {
		content: '';
		border-width: 5px 16px 16px 5px;
		border-color: $c-light transparent transparent $c-light;
		transform: rotate(45deg);
		border-style: solid;
		display: block;
		width: 0;
		height: 0;
		position: absolute;
		top: calc(50% - 5px);
		left: calc(50% - 10px);
	}

	&.__show {
		opacity: .6;
		visibility: visible;

		&:hover {
			cursor: pointer;
			opacity: 1;
		}
	}
}

.custom-object-fit {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	img {
		opacity: 0;
	}
}

.btn {
	background-color: $c-accent;
	border-radius: 30px;
	border: none;
	color: $c-light;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	height: 40px;
	line-height: 40px;
	padding: 0 35px;
	text-align: center;
	text-decoration: none;
	transition: all $time ease-in-out;

	@include md {
		font-size: 18px;
		height: 50px;
		line-height: 50px;
		padding: 0 25px;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: $c-accent-light;
		color: $c-light;
		text-decoration: none;
	}

	&.__outline {
		border: 1px solid $c-accent;
		background: $c-none;
		color: $c-accent;

		@include media-breakpoint-down(sm) {
			line-height: 38px;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: $c-accent;
			color: $c-light;
			text-decoration: none;
		}
	}

	&.__profit {
		background-color: $c-profit;
		font-size: 14px;

		@include md {
			font-size: 20px;
		}

		&:hover,
		&:focus,
		&:active {
			background-color: rgba($c-profit, .8);
		}
		&[disabled] {
		    opacity: 0.5;
		    cursor:default;
		}
		&#load_more {
		    margin: 0 auto;
		    display:block;
		    position: relative;
		}
	}

	&.__icon {
		display: inline-flex;
		align-items: center;
		justify-content: space-between;

		i {
			margin-right: 10px;
		}
	}
}

.social {
	align-items: center;
	display: flex;
	justify-content: center;
	list-style: none;
	padding-left: 0;

	&-item {
		background-position: center;
		background-repeat: no-repeat;
		background-size: 20px;
		height: 30px;
		transition: all $time;
		width: 30px;
		margin-right: 5px;

		&.__instagram {
			background-image: url(../img/svg/instagram.svg);
		}

		&.__twitter {
			background-image: url(../img/svg/twitter.svg);
		}

		&.__facebook {
			background-image: url(../img/svg/facebook.svg);
		}

		&.__youtube {
			background-image: url(../img/svg/youtube.svg);
		}

		&.__telegram {
			background-image: url(../img/svg/telegram.svg);
		}

		&.__viber {
			background-image: url(../img/svg/viber.svg);
		}

		&:hover {
			opacity: .7;
		}

		a {
			display: block;
			height: 100%;
			width: 100%;
		}
	}

	&-share {
		align-items: center;
		background: $c-lgrey-2;
		display: flex;
		height: 66px;
		padding: 0 20px;
		margin: 0 auto;

		@include lg {
			background: $c-none;
			padding-left: 0;
		}

		&-title {
			font-size: 20px;
		}

		.social {
			margin-bottom: 0;

			&-item {
				margin-left: 0;

				@include mobile {
					margin-left: 20px;
				}
			}
		}

	}
}

section {
	padding: 45px 0;

	@include lg {
		padding: 95px 0;
	}

	h2 {
		font-size: 25px;
		font-weight: bold;
		line-height: 28px;
		text-align: center;
		margin-bottom: 30px;

		@include lg {
			font-size: 50px;
			line-height: 60px;
			margin-bottom: 65px;
		}
	}
}

/*PRELOADING------------ */
.preloader {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	background: $c-lgrey-4;
	overflow: hidden;
	min-height: 100vh;

	&-inner {
		display: inline-block;
		width: 30px;
		height: 30px;
		position: fixed;
		z-index:101;
		border: 4px solid $c-profit;
		top: 50%;
		left: 50%;
		animation: preloader 2s infinite ease;
		//
		&::after {
			content: '';
			vertical-align: top;
			display: inline-block;
			width: 100%;
			background-color: $c-profit;
			animation: preloader-inner 2s infinite ease-in;
		}
	}
}

@keyframes preloader {
	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(180deg);
	}

	50% {
		transform: rotate(180deg);
	}

	75% {
		transform: rotate(360deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes preloader-inner {
	0% {
		height: 0%;
	}

	25% {
		height: 0%;
	}

	50% {
		height: 100%;
	}

	75% {
		height: 100%;
	}

	100% {
		height: 0%;
	}
}

.upcoming-training {
	&-container {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	&-title {
		font-size: 25px;
		line-height: 50px;

		@include xl {
			font-size: 30px;
			line-height: 60px;
		}
	}

	&-item {
		background: $c-lgrey;
		margin-bottom: 16px;
		padding: 20px;
		text-align: center;

		@include xl {
			padding: 30px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&-info {
			align-items: center;
			display: flex;
			justify-content: space-between;
		}

		&-title {
			font-weight: bold;
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 40px;
			margin-top: 25px;
			text-align: left;
		}

		&-date {
			font-size: 16px;
			font-weight: bold;
			line-height: 28px;

			@include xl {
				font-size: 22px;
			}
		}

		&-city {
			font-size: 20px;
			line-height: 26px;

			@include lg {
				font-size: 16px;
			}

			@include xl {
				font-size: 20px;
			}
		}
	}
}

.banner {
	padding: 70px 0 15px;

	@include lg {
		padding-bottom: 5px;
		padding-top: 150px;
	}

	&-link {
		background: $c-dark-blue;
		display: flex;
		margin: 0 auto;
		padding: 25px 25px 30px;

		flex-direction: column;
		align-items: center;

		@include sm {
			flex-direction: row;
			justify-content: space-between;
			padding-left: 50px;
			padding-right: 50px;
			max-width: 80%;
		}

		@include lg {
			padding: 50px 115px;
			justify-content: space-between;
		}

		@include xl {
			max-width: 1030px;
		}
	}

	&-logo {
		margin-bottom: 15px;
		width: 115px;

		@include sm {
			margin-bottom: 0;
		}

		@include md {
			width: 200px;
		}
	}

	&-slogan {
		font-size: 30px;
		line-height: 35px;
		font-weight: bold;
		color: $c-profit;
		text-align: center;
		margin-bottom: 0;

		@include lg {
			font-size: 50px;
			line-height: 55px;
		}
	}
}

.pagination {
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: row;
	list-style: none;
	padding-left: 0;
	margin-top: 50px;

	@include lg {
		margin-top: 20px;
	}

	&-item {
		margin-right: 13px;
		transition: all $time;

		&:last-child {
			margin-right: 0;
		}

		span.active, span.current, a.active, a.current {
				background: $c-profit;
				color: $c-light;

				@include hover-focus-active {
					background: rgba($c-profit, .8);
				}
		}

		&.prev {
			a {
				position: relative;

				&::before {
					content: '';
					border: solid #000;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 3px;
					transform: translate(-50%, -50%) rotate(135deg);
					position: absolute;
					left: 50%;
					top: 50%;
					transition: all .2s;
				}

				@include hover-focus-active {
					background: rgba($c-profit, .25);
				}
			}
		}

		a.next {
				position: relative;

				&::before {
					content: '';
					border: solid #000;
					border-width: 0 2px 2px 0;
					display: inline-block;
					padding: 3px;
					transform: translate(-50%, -50%) rotate(-45deg);
					position: absolute;
					left: 50%;
					top: 50%;
					transition: all .2s;
				}

				@include hover-focus-active {
					background: rgba($c-profit, .25);
				}
		}

		&.disabled a {
			cursor: not-allowed;
			opacity: .5;
			pointer-events: none;
		}

		a, span {
			color: $c-default;
			border-radius: 50%;
			font-size: 18px;
			height: 50px;
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			transition: all $time;

			@include hover-focus-active {
				background: rgba($c-profit, .25);
				text-decoration: none;
			}
		}
	}
}

.filters_mobile {
	&-show {
		align-items: center;
		background-image: url(../img/svg/filter-btn-bg.svg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0;
		position: fixed;
		width: 100%;
		z-index: 1;

		@include lg {
			display: none;
		}

		&::before {
			background: $c-accent;
			bottom: 0;
			content: '';
			height: 12px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		&-btn {
			border: none;
			color: $c-light;
			background: $c-none;
			font-weight: bold;
			font-size: 20px;
			line-height: 50px;
			height: 50px;
			height: 50px;
			width: auto;
			position: relative;
			padding: 0 0 0 25px;
			outline: none;

			&::before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 16px;
				width: 14px;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url(../img/svg/icon-filter.svg);
			}
		}
	}

	&-actions {
		display: none;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1051;
		background-color: $c-light;
		width: 100%;
		height: 50px;
		padding: 0 20px;
		outline: none;

		&::before {
			//content: '';
			//position: absolute;
			//width: 100%;
			//height: 50px;
			//bottom: 0;
			////background: $c-accent;
			//display: block;
			//left: 23%;

			//background-image: url(../img/svg/filter-btn-bg.svg);
			//background-position: center;
			//background-repeat: no-repeat;
			//background-size: contain;
		}

		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 12px;
			bottom: 0;
			background: $c-accent;
			display: block;
			left: 0;
		}

		&-container {
			display: flex;
		}

		&-cancel {
			background: $c-light;
			border: 0;
			width: 50%;
			font-size: 20px;
			line-height: 38px;
			height: 38px;
			border-radius: 25px;
			position: relative;
			z-index: 3;
			outline: none;
		}

		&-apply {
			background-color: $c-accent;
			color: $c-light;

			border: 0;
			width: 50%;
			font-size: 20px;
			font-weight: bold;
			line-height: 38px;
			height: 38px;
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			position: relative;
			padding: 0;

			&::before {
				content: '';
				display: block;
				background: $c-accent;
				position: absolute;
				bottom: 0;
				left: -10px;
				height: 12px;
				width: 12px;
				z-index: 1;
			}

			&::after {
				content: '';
				display: block;
				background: $c-accent;
				position: absolute;
				bottom: 0;
				right: -10px;
				height: 12px;
				width: 12px;
				z-index: 1;
			}

			span {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;

				&::before {
					content: '';
					display: block;
					background: $c-light;
					position: absolute;
					bottom: 0;
					left: -26px;
					height: 26px;
					width: 26px;
					z-index: 2;
					border-radius: 50%;
				}

				&::after {
					content: '';
					display: block;
					background: $c-light;
					position: absolute;
					bottom: 0;
					right: -26px;
					height: 26px;
					width: 26px;
					z-index: 2;
					border-radius: 50%;
				}
			}
		}
	}
}

.filters-selected {
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	@include lg {
		margin-bottom: 14px;
	}

	&-item {
		font-size: 12px;
		line-height: 210%;

		color: $c-dark-grey-2;
		position: relative;
		background: rgba($c-filters-status, .31);
		padding: 0 20px 0 9px;
		line-height: 21px;
		display: inline-flex;
		align-items: center;

		margin-right: 13px;
		border-radius: 10px;
		transition: all $time;
		cursor: pointer;
		margin-bottom: 17px;

		&::after {
			content: "";
			display: block;
			position: absolute;
			right: 7px;
			top: 50%;
			transform: translateY(-50%);
			height: 7px;
			width: 7px;
			background-image: url(../img/svg/close.svg);
			background-repeat: no-repeat;
			background-position: center;
		}

		&:hover {
			background: rgba($c-filters-status, .5);
		}
	}

	&-reset {
		cursor: pointer;
		font-weight: bold;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 17px;
		color: #04509B;
	}
}

.side-banner {
	display: none;

	@include lg {
		display: inline-block;
		text-align: center;
		width: 100%;
		max-width: 400px;
	}

	@include media-breakpoint-down(lg) {
		margin-bottom: 20px;
	}

	@include lg {
		margin-top: 20px;
	}

	img {
		width: 100%;
	}
}

.team_section {
	padding: 0;
	text-align: center;

	.team-slider {
		margin-bottom: 30px;

		@include lg {
			margin-bottom: 30px;
		}

		&-item {
			&-link {
				border-radius: 5px;
				color: $c-default;
				display: block;
				margin-bottom: 0;
				padding: 15px 3px 20px;
				text-align: center;
				transition: all $time;

				@include lg {
					padding: 30px 24px 50px;
				}

				@include hover-focus-active {
					//background: $c-light;
					//box-shadow: 0px 2px 50px rgba($c-default, .15);
					color: $c-default;
					text-decoration: none;
				}
			}

			&-image {
				border-radius: 50%;
				display: inline-block;
				height: 82px;
				margin-bottom: 20px;
				width: 82px;

				@include lg {
					height: 246px;
					margin-bottom: 30px;
					width: 246px;
				}
			}

			&-name {
				font-weight: bold;
				font-size: 16px;
				line-height: 1.4;
				margin-bottom: 0;
				min-height: 44px;

				@include lg {
					font-size: 22px;
					margin-bottom: 10px;
					min-height: auto;
				}
			}

			&-position {
				font-size: 16px;
				line-height: 1.4;
				opacity: .6;

				@include lg {
					font-size: 16px;
				}
			}
		}

		.slick-arrow {
			top: 20%;

			@include lg {
				top: 135px;
			}
		}
	}
}


.read_program {
	padding: 30px 0 75px;
	text-align: center;

	@include lg {
		padding: 40px 0 135px;
	}

	&-title {
		margin-bottom: 8px;

		@include lg {
			margin-bottom: 12px;
		}
	}

	&-txt {
		font-size: 20px;
		line-height: 1.4;
		margin-bottom: 0;

		@include lg {
			font-size: 35px;
			line-height: 1.8;
		}
	}

	.btn {
		margin-top: 40px;

		@include lg {
			margin-top: 70px;
		}
	}
}