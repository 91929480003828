.search {
    &-form {
        position: relative;

        input {
            background: $c-light;
            border-color: $c-grey;
            border-radius: 6px;
            color: $c-default;
            font-size: 16px;
            height: 50px;
            line-height: 50px;
            padding-right: 50px;
            text-align: left;
            width: 100%;

            @include lg {
                font-size: 18px;
            }
        }

        button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(../img/svg/search.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-color: $c-none;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            padding: 0;

            @include hover-focus-active {
                background-color: $c-none;
                opacity: .5;
            }
        }
    }

    &-title {
        border-bottom: 1px solid $c-lgrey-4;
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        margin-top: 25px;
        margin-bottom: 27px;
        padding-bottom: 15px;

        @include lg {
            font-size: 35px;
            line-height: 45px;
            margin-top: 40px;
            margin-bottom: 32px;
            padding-bottom: 30px;
        }
    }

    &-results {
        &-item {
            display: flex;
            flex-direction: column;
            //padding: 10px 0;
            margin-bottom: 15px;

            @include lg {
                flex-direction: row;
            }

            &-info {
                color: $c-accent;
                font-size: 16px;
                font-weight: bold;
                line-height: 21px;

                @include media-breakpoint-down(md) {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                }

                @include lg {
                    flex-shrink: 0;
                    padding-right: 10px;
                    width: 138px;
                }

                p {
                    margin-bottom: 10px;

                    @include media-breakpoint-down(md) {
                        flex: 0 0 50%;
                    }
                }
            }

            &-title {
                color: $c-default;
                display: block;
                font-size: 16px;
                line-height: 1.2;
                //text-decoration: none;
                margin-bottom: 10px;

                @include lg {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }
                
                @include hover-focus-active {
                    color: $c-accent;
                    text-decoration: none;
                }
            }

            &-preview {
                font-size: 14px;
                line-height: 1.3;
                margin-bottom: 5px;
                opacity: .6;

                @include lg {
                    font-size: 16px;
                }
            }

            &-category {
                font-size: 12px;
                font-family: $typography-font;
                line-height: 1;
            }
        }
    }
}