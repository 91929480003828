.breadcrumbs {
    color: $c-default;
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin: 40px auto;
    position: relative;

    @include lg {
        margin-bottom: 40px;
        margin-top: 20px;
    }

    &::after {
        content: '';
        background: linear-gradient(to right,rgba(255,255,255,0) 0,#fff 100%);
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;

        @include md {
            width: 60px;
        }
    }

    &-container {
        margin-top: 0;
        overflow-x: auto;

        padding-left: 0;
        //text-overflow: ellipsis;
        white-space: nowrap;

        /* Hide scrollbar for IE and Edge */
        -ms-overflow-style: none;

        margin-bottom: -14px;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
    }

    li {
        display: inline-block;
        position: relative;

        &:not(:last-child) {
            padding-right: 20px;

            &::after {
                color: $c-grey;
                content: '/';
                position: absolute;
                right: 6px;
                top: 0;
            }
        }

        a {
            color: $c-grey;
        }
    }

    + section {
        padding-top: 0;
    }
}