.contacts {

    &-item {
        margin-bottom: 60px;

        @include lg {
            margin-bottom: 70px;
        }
    }

    &-title {
        font-size: 25px;
        font-weight: bold;
        line-height: 30px;
        margin-bottom: 25px;
        text-align: left;

        @include lg {
            font-size: 35px;
            line-height: 60px;
        }
    }

    &-location {
        margin-bottom: 15px;

        &-city {
            font-size: 25px;
            font-weight: bold;
            line-height: 60px;
            margin-bottom: 0;

            @include lg {
                margin-left: 50px;
            }
        }

        &-link {
            color: $c-default;
            display: block;
            font-size: 18px;
            line-height: 30px;

            @include lg {
                margin-left: 50px;
            }

            @include hover-focus-active {
                color: $c-accent;
            }
        }
    }

    &-map {
        @include lg {
            padding-top: 45px;
        }

        iframe {
            max-width: 100%;

            @include media-breakpoint-down(sm) {
                height: 400px;
                margin-top: 20px;
            }
        }
    }
}