.schedule {

    &-section {
        //padding-bottom: 0;
    }

    .page-title {
        text-align: center;
    }

    &-title {
        font-weight: bold;
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 25px;

        @include lg {
            font-size: 35px;
            line-height: 45px;
            margin-bottom: 55px;
        }
    }

    &-filters {
        margin-bottom: 30px;

        @include lg {
            margin-bottom: 50px;
        }

        &-btn {
            width: 100%;

            //@include lg {
            //    margin-top: 20px;
            //}
            //
            //@include xl {
            //    margin-top: 0;
            //}
        }
    }

    &-trainings {
        &-item {
            background: $c-lgrey-5;
            border-radius: 5px;
            margin: 0 auto 15px;
            padding: 22px 5px 32px;
            transition: all $time;

            @include sm {
                padding-left: 15px;
                padding-right: 15px;
            }

            @include lg {
                background: $c-lgrey;
                border-radius: 10px;
                margin-bottom: 28px;
                padding-left: 0;
            }

            @include xl {
                padding: 30px 50px 30px 10px;
            }

            &:hover {
                box-shadow: 0px 2px 15px rgba($c-default, .25);

                @include lg {
                    .schedule-trainings-buttons {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        &-date {
            font-size: 18px;
            line-height: 23px;
            font-weight: bold;
            margin-bottom: 0;

            @include md {
                font-size: 25px;
                line-height: 35px;
            }

            @include lg {
                margin-bottom: 25px;
                padding-left: 25px;
            }

            @include xl {
                font-size: 30px;
                line-height: 45px;
                padding-left: 40px;
            }

            span {
                //margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                @include lg {
                    margin-right: 0;
                }
            }
        }

        &-city {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 0;
            text-align: center;

            @include lg {
                display: block;
                padding-left: 25px;
                text-align: left;
            }

            @include xl {
                padding-left: 40px;
            }
        }

        &-staff {
            margin-bottom: 22px;
            margin-top: 20px;
            position: relative;

            &[data-quantity="0"] {
                margin-bottom: 0;

                &::after {
                    display: none;
                }
            }

            &::after {
                content: '+' attr(data-quantity);
                align-items: center;
                border-radius: 50%;
                border: 1px solid;
                background: $c-light;
                color: $c-default;
                display: flex;
                font-size: 16px;
                font-weight: bold;
                height: 48px;
                justify-content: center;
                position: relative;
                top: -15px;
                margin-bottom: -35px;
                width: 48px;
            }

            @include lg {
                margin-top: 0;

                &::after {
                    display: none;
                }
            }

            &-item {
                align-items: center;
                display: flex;
                position: relative;

                &:nth-child(n + 2) {
                    display: none;

                    @include lg {
                        display: flex;
                        position: relative;
                        top: -15px;
                        margin-bottom: -15px;
                    }

                    @include xl {
                        top: -20px;
                        margin-bottom: -20px;
                    }
                }

                a {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }

            &-image {
                border-radius: 50%;
                height: 48px;
                width: 48px;
                margin-right: 12px;
                position: relative;
                z-index: 1;

                @include lg {
                    border: 2px solid $c-light;
                    height: 65px;
                    width: 65px;
                }

                @include xl {
                    height: 85px;
                    width: 85px;
                    margin-right: 20px;
                }
            }

            &-name {
                font-weight: bold;
                font-size: 18px;
                line-height: 1.4;
                margin-bottom: 0;
		
		@include mobile {
		    font-weight: 400;
		    font-size: 16px;
		    line-height:22px;
		}
            }

            &-position {
                font-size: 12px;
                line-height: 1.1;
                margin-bottom: 0;
                opacity: .6;
            }
        }

        &-title {
            color: $c-default;
            font-size: 18px;
            line-height: 1.2;
            margin-top: 30px;
            margin-bottom: 15px;

	    @include mobile {
                font-weight: bold;
                font-size: 22px;
		display: inline-block;
		margin-top: 15px;
		margin-bottom: 5px;
		line-height: 26px;
            }

            @include lg {
                margin-bottom: 25px;
                margin-top: 0;
            }
        }

        &-description {
            display: none;

            @include lg {
                opacity: .8;
                display: block;
                font-size: 16px;
                line-height: 1.3;
                margin-bottom: 20px;
            }
        }

        &-bottom {
            @include xl {
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }

        &-cost {
            font-size: 18px;
            line-height: 1.3;
            margin-bottom: 20px;

            @include lg {
                font-size: 20px;
            }

            @include xl {
                margin-bottom: 0;
            }
        }

        &-buttons {
            display: flex;
            justify-content: space-between;

            @include lg {
                display: block;
                visibility: hidden;
                opacity: 0;
                transition: all $time;
            }

            .btn {
                height: 40px;
                line-height: 40px;
                flex: 0 0 calc(50% - 5px);
                padding-left: 5px;
                padding-right: 5px;

                @include lg {
                    font-size: 18px;
                    flex: 0;
                    margin-right: 25px;
                    width: 160px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        
        &-all {
            margin-top: 35px;
            text-align: center;

            @include md {
                margin-top: 85px;
            }
        }
    }

    .filters_mobile {
        &-show {
            align-items: center;
            background-image: url(../img/svg/filter-btn-bg.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 0;
            display: flex;
            justify-content: center;
            left: 0;
            position: fixed;
            width: 100%;
            z-index: 1;

            @include lg {
                display: none;
            }

            &::before {
                background: $c-accent;
                bottom: 0;
                content: '';
                height: 12px;
                left: 0;
                position: absolute;
                width: 100%;
            }

            &-btn {
                border: none;
                color: $c-light;
                background: $c-none;
                font-weight: bold;
                font-size: 20px;
                line-height: 50px;
                height: 50px;
                height: 50px;
                width: auto;
                position: relative;
                padding: 0 0 0 25px;
                outline: none;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 14px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-image: url(../img/svg/icon-filter.svg);
                }
            }
        }

        &-actions {
            display: none;
            position: fixed;
            left: 0;
            bottom: 0;
            z-index: 1051;
            background-color: $c-light;
            width: 100%;
            height: 50px;
            padding: 0 20px;
            outline: none;

            &::before {
                //content: '';
                //position: absolute;
                //width: 100%;
                //height: 50px;
                //bottom: 0;
                ////background: $c-accent;
                //display: block;
                //left: 23%;

                //background-image: url(../img/svg/filter-btn-bg.svg);
                //background-position: center;
                //background-repeat: no-repeat;
                //background-size: contain;
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 12px;
                bottom: 0;
                background: $c-accent;
                display: block;
                left: 0;
            }

            &-container {
                display: flex;
            }

            &-cancel {
                background: $c-light;
                border: 0;
                width: 50%;
                font-size: 20px;
                line-height: 38px;
                height: 38px;
                border-radius: 25px;
                position: relative;
                z-index: 3;
                outline: none;
            }

            &-apply {
                background-color: $c-accent;
                color: $c-light;

                border: 0;
                width: 50%;
                font-size: 20px;
                font-weight: bold;
                line-height: 38px;
                height: 38px;
                border-top-left-radius: 25px;
                border-top-right-radius: 25px;
                position: relative;
                padding: 0;

                &::before {
                    content: '';
                    display: block;
                    background: $c-accent;
                    position: absolute;
                    bottom: 0;
                    left: -10px;
                    height: 12px;
                    width: 12px;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    display: block;
                    background: $c-accent;
                    position: absolute;
                    bottom: 0;
                    right: -10px;
                    height: 12px;
                    width: 12px;
                    z-index: 1;
                }

                span {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        background: $c-light;
                        position: absolute;
                        bottom: 0;
                        left: -26px;
                        height: 26px;
                        width: 26px;
                        z-index: 2;
                        border-radius: 50%;
                    }

                    &::after {
                        content: '';
                        display: block;
                        background: $c-light;
                        position: absolute;
                        bottom: 0;
                        right: -26px;
                        height: 26px;
                        width: 26px;
                        z-index: 2;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    // Separate section
    &.__short {
        //@include md {
        //    padding-bottom: 132px;
        //}
        //
        //@include lg {
        //    padding-top: 110px;
        //}

        h2 {
            @include lg {
                margin-bottom: 65px;
            }
        }

        .schedule-filters {
            display: flex;
            margin-bottom: 20px;

            @include md {
                padding-bottom: 32px;
                border-bottom: 1px solid $c-accent;
            }

            &-form {
                align-items: center;
                display: flex;
                flex-direction: column;

                @include md {
                    flex-direction: row;
                }
            }

            &-item {
                width: 290px;

                @include md {
                    width: 295px;
                }

                &:nth-child(n + 2) {
                    margin-top: 20px;

                    @include md {
                        margin-top: 0;
                        margin-left: 20px;
                    }
                }
            }

            &-download {
                display: none;

                @include md {
                    display: inline-block;
                    text-align: right;
                }
            }
        }

        .schedule-info {
            align-items: center;
            margin-bottom: 24px;

            @include md {
                margin-bottom: 15px;
            }

            &-title {
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 20px;
                text-align: center;

                @include md {
                    margin-bottom: 0;
                    text-align: left;
                }

                @include lg {
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            &-download {
                padding-right: 20px;
                position: relative;

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 14px;
                    background-repeat: no-repeat;
                    background-image: url(../img/svg/download.svg);
                }

                @include md {
                    display: none;
                }
            }

            &-additional {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @include md {
                    justify-content: flex-end;
                }
            }

            &-contacts {
                display: flex;
                list-style: none;
                padding-left: 0;
                justify-content: flex-end;
                margin-bottom: 0;

                @include md {
                    text-align: right;
                }

                a {
                    color: $c-default;
                    font-size: 0;
                    line-height: 0;
                    display: flex;
                    align-items: center;
                    //text-decoration: none;
                    transition: all $time;

                    @include hover-focus-active {
                        color: $c-accent;
                    }

                    @include media-breakpoint-down(sm) {
                        background: #E7E7E7;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        justify-content: center;

                        &:hover,
                        &:focus,
                        &:active {
                            background: $c-accent;

                            &.__phone {
                                svg {
                                    fill: $c-light;
                                }
                            }

                            &.__email {
                                svg {
                                    stroke: $c-light;
                                }
                            }
                        }
                    }

                    @include md {
                        font-size: 16px;
                        line-height: 23px;
                        font-weight: bold;
                    }

                    @include lg {
                        font-size: 18px;
                    }

                    &:first-child {
                        margin-right: 35px;

                        @include md {
                            margin-right: 20px;
                        }

                        @include lg {
                            margin-right: 60px;
                        }
                    }

                    svg {
                        opacity: .7;

                        @include md {
                            margin-right: 5px;
                        }

                        @include lg {
                            margin-right: 15px;
                        }
                    }

                    &.__email {
                        svg {
                            stroke: $c-default;
                        }
                    }
                }
            }
        }


        .schedule-table {
            margin-bottom: 28px;

            @include md {
                margin-bottom: 42px;
            }

            &-item {
                background: $c-lgrey;
                border-radius: 5px;
                padding: 17px 13px;
                position: relative;
                display: block;
                transition: all $time/3 ease-in-out;
                cursor: pointer;

                @include md {
                    border-radius: 10px;
                    display: grid;
                    grid-template-columns: 1.5fr 1fr;
                    grid-column-gap: 10px;
                }

                @include lg {

                    grid-template-columns: 1.5fr 2fr;
                    padding: 20px 25px;
                    //grid-column-gap: 10px;
                }

                @include xl {
                    grid-template-columns: 1fr 2fr;
                    padding: 25px 42px 22px 35px;
                }

                &:nth-child(n + 2) {
                    margin-top: 10px;

                    @include md {
                        margin-top: 5px;
                    }
                }

                &:hover {
                    box-shadow: 0 -10px 0 $c-lgrey,
                    0 10px 0 $c-lgrey,
                    0 -10px 10px rgba($c-default, .25),
                    0 10px 10px rgba($c-default, .25),
                    0 0px 10px rgba($c-default, .25);
                    z-index: 1;

                    .btn.__outline {
                        background: $c-accent;
                        color: $c-light;
                    }
                }

                &-info {
                    margin-bottom: 10px;
                    display: grid;
                    grid-template-columns: 1fr 1fr 2fr;
                    grid-column-gap: 12px;
                    align-items: center;

                    @include lg {
                        margin-bottom: 0;
                    }
                }

                &-date {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 23px;

                    @include md {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }

                &-image {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 20px;
                    position: relative;

                    @include sm {
                        flex-direction: row;
                        padding-bottom: 0;
                    }

                    &[data-quantity="0"] {
                        padding-bottom: 0;

                        &::after {
                            display: none;
                        }
                    }

                    &::after {
                        content: '+' attr(data-quantity);
                        align-items: center;
                        border-radius: 50%;
                        border: 1px solid;
                        background: $c-light;
                        color: $c-default;
                        display: flex;
                        font-size: 16px;
                        font-weight: bold;
                        height: 48px;
                        justify-content: center;
                        position: relative;
                        top: -15px;
                        margin-bottom: -35px;
                        width: 48px;

                        flex: 0 0 48px;

                        @include sm {
                            left: -15px;
                            top: auto;
                        }
                    }



                    img {
                        height: 48px;
                        width: 48px;
                        border-radius: 50%;
                        position: relative;

                        &:first-child {
                            z-index: 1;
                        }

                        &:nth-child(2) {
                            top: -10px;
                            z-index: 0;

                            @include sm {
                                top: auto;
                                left: -10px;
                            }
                        }
                    }
                }

                &-trainer {
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 28px;
		    @include mobile {
			font-weight: 400;
			font-size: 16px;
		    }
		    

                    span {
                        display: block;
                    }
                }

                &-course {

                    @include lg {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    p {
                        font-size: 14px;
                        line-height: 18px;
                        margin-bottom: 0;
			@include mobile {
			    font-weight: bold;
			    font-size: 22px;
			    display: inline-block;
			    margin-top: 15px;
			    margin-bottom: 5px;
			    line-height:24px;
			}

                        @include md {
                            font-size: 20px;
                            line-height: 28px;
                            padding-right: 40px;
                        }
                    }

                    .btn {
                        display: none;

                        @include lg {
                            display: inline-block;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }

        .schedule-all {
            text-align: center;
        }

    }
}