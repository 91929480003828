@mixin sm() {
    @include media-breakpoint-up(sm) {
        @content;
    }
}

@mixin md() {
    @include media-breakpoint-up(md) {
        @content;
    }
}

@mixin lg() {
    @include media-breakpoint-up(lg) {
        @content;
    }
}

@mixin xl() {
    @include media-breakpoint-up(xl) {
        @content;
    }
}

@mixin media-max($size) {
    @media all and (max-width: $size + px) {
        @content;
    }
}

@mixin media($size) {
    @media all and (min-width: $size + px) {
        @content;
    }
}

@mixin mobile() {
    @media all and (max-width: 991px) {
        @content;
    }
}

@mixin note() {
    @media all and (min-width: 992px) {
        @content;
    }
}

@mixin pc() {
    @media all and (min-width: 1441px) {
        @content;
    }
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
