.training {
    &-section {

        @include lg {
            padding-bottom: 10px;
        }
    }

    &-container {
        margin-bottom: 45px;
        position: relative;

        &::after {
            content: '';
            display: block;
            left: 0;
            width: 100%;
            bottom: -45px;
            height: 1px;
            background: $c-accent;
            position: absolute;

            @include lg {
                bottom: -120px;
            }
        }

        @include lg {
            margin-bottom: 120px;
        }

        .page-title {
            font-weight: bold;
            font-size: 25px;
            line-height: 28px;
            margin-bottom: 25px;

            @include lg {
                font-size: 40px;
                line-height: 53px;
            }

            @include xl {
                max-width: 715px;
            }
        }

        .social-share {
            @include xl {
                margin-left: 0;
                max-width: 715px;
            }
        }

        &-side {
            @include xl {
                position: absolute;
                right: 0;
                width: 400px;
                top: 0;
                margin-top: 20px;
            }

            .side-banner {
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
    }

    &-closest {
        padding: 28px 0 34px;
        text-align: center;

        @include lg {
            border: 1px solid #848484;
            border-radius: 5px;
            padding: 28px 25px 34px;
        }

        &-title {
            display: none;

            @include lg {
                display: block;
                font-weight: bold;
                font-size: 18px;
                line-height: 23px;
                margin-bottom: 22px;
                text-align: center;
            }
        }

        &-info {
            text-align: left;
            
            @include md {
                text-align: center;
            }

            @include lg {
                font-size: 18px;
                line-height: 23px;
            }

            &-item {
                display: inline-block;
                padding-left: 30px;
                position: relative;
                margin-bottom: 30px;

                @include lg {
                    margin-bottom: 0;
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    height: 20px;
                    width: 20px;
                    opacity: .6;
                }

                &.location {

                    @include media-breakpoint-down(lg) {
                        font-weight: bold;
                    }

                    &::before {
                        background-image: url(../img/svg/location.svg);
                    }
                }

                &.dates {
                    @include media-breakpoint-down(lg) {
                        font-weight: bold;
                    }

                    &::before {
                        background-image: url(../img/svg/calendar.svg);
                    }
                }

                &.costs {
                    text-align: left;

                    @include lg {
                        display: inline-block;
                        margin-top: 30px;
                        margin-bottom: 40px;
                    }

                    &::before {
                        top: 2px;
                        transform: none;
                        background-image: url(../img/svg/cost.svg);
                    }

                    .costs-item {
                        display: block;
                        margin-bottom: 8px;

                        &.__old {
                            position: relative;

                            &::after {
                                content: '';
                                display: block;
                                height: 2px;
                                width: calc(100% + 8px);
                                background: red;
                                position: absolute;
                                left: -4px;
                                top: calc(50% - 1px);
                            }
                        }

                        &.__current {
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        &-staff {
            margin-bottom: 5px;
            padding: 0 5px;

            &-title {
                font-weight: bold;
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 20px;

                @include lg {
                    font-size: 18px;
                    line-height: 23px;
                    margin-bottom: 15px;
                }
            }
        }

        &-methodists {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            &-item {

                margin-bottom: 20px;
                padding: 0 5px;
                width: 33.3333%;

                a {
                    align-items: center;
                    display: inline-flex;
                    color: $c-default;
                    flex-direction: column;
                    transition: all $time/2;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $c-accent;
                        text-decoration: none;

                        img {
                            box-shadow: 0px 0px 6px $c-default;
                        }
                    }
                }

                img {
                    border-radius: 50%;
                    height: 82px;
                    width: 82px;
                    margin-bottom: 15px;
                    transition: all $time;
                }

                span {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 1.4;
                }
            }
        }

        &-bottom {
            @include media-breakpoint-down(md) {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100%;
                display: flex;
                z-index: 2;
                height: 50px;
            }

            &-info {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 0 5px;

                @include media-breakpoint-down(md) {
                    background: $c-light;
                    flex: 0 0 50%;
                }
            }

            &-places {
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;

                @include lg {
                    font-size: 18px;
                    font-weight: normal;
                    line-height: 23px;
                    margin-bottom: 15px;
                }
            }

            &-price {
                font-weight: bold;
                font-size: 18px;
                line-height: 23px;


                @include lg {
                    font-size: 22px;
                    line-height: 28px;
                    margin-bottom: 15px;
                }
            }

            .btn {
                @include media-breakpoint-down(md) {
                    background: $c-accent;
                    color: $c-light;
                    flex: 0 0 50%;
                    margin-bottom: 0;
                    border-radius: 0;
                    height: 100%;
                    padding: 0 10px;
                }
            }
        }
    }

    &-content {
        @include xl {
            max-width: 715px;
        }

        table {
            td:first-child {
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                vertical-align: middle;

                @include lg {
                    font-size: 18px;
                }
            }
        }
    }

    h2 {
        font-size: 25px;
        line-height: 32px;

        @include lg {
            font-size: 35px;
            line-height: 45px;
        }
    }

    .our_programs {

        @include lg {
            padding-bottom: 160px;
        }
    }
}