.employees {

    .page-title {
        margin-bottom: 15px;
        text-align: center;
        
        @include lg {
            //margin-bottom: 15px;
            text-align: left;
        }
    }

    .page-subtitle {
        font-size: 14px;
        line-height: 20px;
        text-align: center;

        @include lg {
            font-size: 18px;
            line-height: 29px;
            text-align: left;
        }
    }

    &-form {
        margin-top: 25px;
        margin-bottom: 40px;

        @include lg {
            margin-top: 50px;
        }

        input {
            width: 100%;
        }

        .form-item {
            margin-bottom: 26px;

            @include lg {
                margin-bottom: 0;
            }
        }

        &-input {
            border-color: $c-grey;
            color: #9E9E9E;
            font-size: 16px;
            font-weight: normal;
            padding-left: 50px;
            position: relative;

            background-position: 17px center;
            background-repeat: no-repeat;
            background-image: url(../img/svg/search.svg);
            transition: all $time;
            text-align: left;

            &:focus {
                box-shadow: 0 0 0 1px $c-grey;
            }

            &.validation-item.error:focus {
                box-shadow: 0 0 0 1px $c-error;
            }
        }

        &-example {

            @include media-breakpoint-down(md) {
                display: none;
            }

            @include lg {
                color: $c-grey;
                font-size: 14px;
                line-height: 18px;
                margin-top: 8px;
                padding-left: 10px;
            }
        }

        &-submit {
            width: 100%;
        }


    }

    &-search {

        &-title {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 60px;

            @include lg {
                font-size: 30px;
                margin-bottom: 30px;
            }
        }

        &-item {
            padding: 12px 0 20px;
            border-bottom: 1px solid $c-lgrey-4;

            &:first-of-type {
                border-top: 1px solid $c-lgrey-4;
            }

            &-date {
                font-size: 14px;
                font-weight: bold;
                display: flex;
                align-items: center;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                }

                @include md {
                    font-size: 16px;
                }
            }

            &-position {
                font-weight: bold;
                font-size: 18px;
                color: $c-default;
                //text-decoration: none;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 10px;
                    text-align: right;
                }

                @include md {
                    font-size: 20px;
                }
                
                @include hover-focus-active {
                    text-decoration: underline;
                }
            }

            &-city {
                font-weight: bold;
                font-size: 16px;

                @include md {
                    font-size: 20px;
                    text-align: center;
                }
            }

            &-cost {
                font-weight: bold;
                font-size: 16px;
                text-align: right;

                @include md {
                    font-size: 20px;
                }
            }

            &-description {
                color: $c-grey;
                font-size: 12px;
                line-height: 20px;
                margin-top: 20px;

                @include md {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }

        .pagination {
            margin-top: 30px;

            @include lg {
                margin-top: 70px;
            }
        }
    }

    &-filters {

        @include media-breakpoint-down(md) {
            display: none;
        }


        @include lg {
            padding-left: 20px;
            padding-top: 10px;
            //position: relative;
            //right: 0;
            //top: 0;
        }

        @include xl {
            padding-left: 77px;

        }


        &-title {
            font-weight: bold;
            font-size: 22px;
            line-height: 28px;

            @include lg {
                margin-bottom: 60px;
            }
        }


        &-selected {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            @include lg {
                margin-bottom: 14px;
            }


            &-item {
                font-size: 12px;
                line-height: 210%;

                color: $c-dark-grey-2;
                position: relative;
                background: rgba($c-filters-status, .31);
                padding: 0 20px 0 9px;
                line-height: 21px;
                display: inline-flex;
                align-items: center;

                margin-right: 13px;
                border-radius: 10px;
                transition: all $time;
                cursor: pointer;
                margin-bottom: 17px;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 7px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 7px;
                    width: 7px;
                    background-image: url(../img/svg/close.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &:hover {
                    background: rgba($c-filters-status, .5);
                }
            }

            &-reset {
                cursor: pointer;
                font-weight: bold;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 17px;

                color: #04509B;
            }
        }

        &-form {

        }


        &-block {
            margin-bottom: 30px;

            &-name {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 15px;
            }

            &-container {

                .form-checkbox-label::before {
                    top: 5px;
                }

            }
        }
    }


    // Employees post page
    &-post {

        &-form {

            input,
            textarea {
                border-color: $c-lgrey-4;
                font-size: 16px;
                max-width: 573px;
                opacity: .5;
                width: 100%;
            }

            textarea {
                height: 165px;
            }

            .form-item-file {
                max-width: 573px;

            }

            .row > .col-12 {

                &:last-child {

                    @include xl {
                        padding-left: 47px;
                    }
                }

            }

            //.form-item {
            //    margin-bottom: 20px;
            //
            //    @include lg {
            //        margin-bottom: 25px;
            //    }
            //}

            &-submit {
                display: block;
                margin: 45px auto 0;
                width: 190px;
            }

        }







    }


}