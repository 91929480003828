.methodist {

    &-content {
        position: relative;

        &-image {
            @include media-breakpoint-down(md) {
                text-align: center;
                width: 50%;
            }

            @include lg {
                position: absolute;
                left: 0;
                top: 0;
            }

            img {
                border-radius: 50%;
                height: 82px;
                width: 82px;

                @include md {
                    height: 150px;
                    width: 150px;
                }

                @include lg {
                    height: 246px;
                    width: 246px;
                }
            }
        }

        &-top {
            @include media-breakpoint-down(md) {
                position: absolute;
                right: 0;
                top: 0;
                width: 50%;
                font-size: 14px;
            }
        }

        .methodist-content-name {
            margin-top: 0;
            margin-bottom: 15px;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
            }

            @include lg {
                margin-bottom: 75px;
            }
        }

        &-txt {
            @include media-breakpoint-down(md) {
                margin-top: 40px;
            }

            @include lg {
                padding-left: 315px;
            }
        }

        &-socials {

            @include lg {
                position: absolute;
                top: 266px;
                left: 0;
                width: 246px;
            }

            .social {
                justify-content: flex-start;

                @include lg {
                    justify-content: center;
                }

                &-item {
                    margin-bottom: 0;
                    margin-right: 10px;
                    padding-left: 0;

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}