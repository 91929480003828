.team {

    .page-title {
        text-align: center;
    }
    
    &-item {
        border-radius: 5px;
        color: $c-default;
        display: block;
        margin-bottom: 10px;
        padding: 15px 3px 20px;
        text-align: center;
        transition: all $time;

        @include lg {
            padding: 30px 24px 50px;
            margin-bottom: 26px;
        }

        @include hover-focus-active {
            background: $c-light;
            box-shadow: 0px 2px 50px rgba($c-default, .15);
            color: $c-default;
            text-decoration: none;
        }

        &-image {
            border-radius: 50%;
            height: 82px;
            margin-bottom: 20px;
            width: 82px;

            @include lg {
                height: 246px;
                margin-bottom: 30px;
                width: 246px;
            }
        }

        &-name {
            font-weight: bold;
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 0;
            min-height: 44px;

            @include lg {
                font-size: 22px;
                margin-bottom: 10px;
                min-height: auto;
            }
        }

        &-position {
            font-size: 14px;
            line-height: 1.4;
            opacity: .6;

            @include lg {
                font-size: 16px;
            }
        }
    }
}