.intro {
    padding: 0;

    &-slider {

        &-slide {
            height: 360px;
            position: relative;
            padding: 40px 30px 50px;
            text-align: center;

            @include sm {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 500px;
            }

            @include md {
                height: 640px;
            }

            @include lg {
                padding-left: 100px;
                padding-right: 100px;
            }

            &-title {
                font-weight: bold;
                font-size: 25px;
                line-height: 28px;
                margin-bottom: 20px;
                text-align: left;
                text-shadow: 0 0 2px rgba($c-light, .8);

                @include md {
                    font-size: 50px;
                    line-height: 60px;
                    max-width: 70%;
                }

                span {
                    display: block;
                }
            }

            &-info {
                font-size: 25px;
                line-height: 32px;
                margin-bottom: 50px;
                text-align: left;

                @include md {
                    margin-bottom: 30px;
                }
            }



            &-bg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
                border-radius: 8px;
                object-fit: cover;
            }
        }
    }

}

.digits {
    background: $c-lgrey-2;

    //@include lg {
    //    padding-top: 90px;
    //}

    &-item {
        margin-bottom: 12px;

        @include lg {
            margin-bottom: 18px;
        }

        &-container {
            align-items: center;
            background: $c-light;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            padding: 25px;
            margin-bottom: 12px;
            height: 100%;

            @include lg {
                margin-bottom: 18px;
                padding: 40px 45px 25px 45px;
            }
        }

        &-counter {
            font-size: 70px;
            font-weight: bold;
            color: $c-accent;
            line-height: 1;
            margin-bottom: 20px;

            @include lg {
                font-size: 90px;
            }
        }

        &-text {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.3;
            text-align: center;
            margin-bottom: 0;

            @include lg {
                font-size: 20px;
            }
        }
    }
}

.our_programs {
    h2 {
        @include lg {
            margin-bottom: 105px;
        }
    }

    .banner {

        @include lg {
            margin-top: 55px;
        }
    }


    &-item {
        margin-bottom: 10px;

        @include lg {
            margin-bottom: 16px;
        }

        &:nth-child(odd) {
            padding-right: 5px;

            @include md {
                padding-right: 10px;
            }
        }

        &:nth-child(even) {
            padding-left: 5px;

            @include md {
                padding-left: 10px;
            }
        }

        &-container {
            align-items: center;
            background: $c-light;
            border-radius: 15px;
            border: 1px solid $c-accent;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-start;
            padding: 33px 8px 25px;
            transition: all $time/2;
            //text-decoration: none;
            color: $c-default;

            @include lg {
                padding: 65px 60px 25px;
            }

            &:hover,
            &:focus,
            &:active {
                box-shadow: 0px 0px 20px rgba($c-accent-dark, .47);
                background: $c-accent;
                text-decoration: none;

                svg {
                    path[stroke] {
                        stroke: $c-profit;
                    }

                    path[fill] {
                        fill: $c-profit;
                    }
                }

                .our_programs-item-text {
                    color: $c-light;
                }
            }
        }

        svg {
            margin-bottom: 15px;

            @include lg {
                margin-bottom: 35px;
            }
        }

        &-text {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            text-align: center;

            @include lg {
                font-size: 20px;
            }
        }
    }
}

.consultation {
    background: $c-accent;
    color: $c-light;
    text-align: center;

    &-form {
        &-title {
            font-size: 22px;
            line-height: 28px;
            font-weight: bold;
            margin: 0 auto 30px;
            max-width: 935px;

            @include md {
                font-size: 35px;
                line-height: 45px;
                margin-bottom: 40px;
            }
        }

        &-inputs {
            display: flex;
            flex-direction: column;
            max-width: 300px;
            margin: 0 auto;

            @include sm {
                flex-direction: row;
                align-items: center;
                justify-content: center;
                max-width: none;
            }

            input {
                background: $c-none;
                border-color: $c-light;
                color: $c-light;
                width: 100%;

                @include sm {
                    width: auto;
                }

                &::placeholder {
                    color: $c-light;
                }
            }

            button {
                margin-top: 20px;
                width: 100%;

                @include sm {
                    margin-top: 0;
                    margin-left: 20px;
                    width: auto;
                }
            }
        }
    }
}

.partners {
    background: $c-lgrey;

    &-logos {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include md {
            justify-content: center;

        }

        img {
            align-self: center;
            border-radius: 3px;
            width: calc(50% - 5px);
            margin-bottom: 10px;
            max-width: 190px;


            @include media(530) {
                width: calc(33.33333% - 10px);

                &:nth-child(odd) {

                }
            }

            @include md {
                margin: 10px 5px;
            }

            @include lg {
                margin: 10px;
                width: auto;


            }

        }
    }
}

.feedback {
    text-align: center;

    .container {
        @include media-breakpoint-between(md, lg) {
            padding-left: 30px;
            padding-right: 30px;
            box-sizing: border-box;
        }
    }

    &-slider {
        margin-bottom: 50px;

        @include md {
            padding: 0 40px;
        }

        .slick-arrow {
            top: 32px;


            @include lg {
                top: 25px;
            }
        }
    }

    &-slide.feedbacks-item {
        margin-bottom: 0;
    }
}

.certificates {
    text-align: center;

    .section-title {
        @include md {
            margin-bottom: 100px;
        }
    }

    &-item {
        margin: 0 auto 40px;
        max-width: 433px;

        @include lg {
            margin-bottom: 0;
        }

        &-image {
            margin-bottom: 10px;

            @include md {
                margin-bottom: 30px;
            }
        }

        &-description {
            font-size: 16px;
            line-height: 21px;

            @include md {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}

.afu-seminars {
    &-slider {
        margin-bottom: 50px;

        @include md {
            margin-bottom: 80px;
        }
    }

    &-slide {
        display: block;
        position: relative;
        //width: 100%;
        padding-bottom: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &-text {
        max-width: 820px;
        margin: 0 auto;
        position: relative;

        p {
            font-size: 18px;
            margin-bottom: 20px;
        }

    }
}

.expandable {
    position: relative;

    &-arrow {
        border: none;
        display: block;
        margin: 55px auto 0;
        position: relative;
        height: 30px;
        width: 30px;
        background: $c-accent;
        padding: 0;
        border-radius: 50%;
        animation: infoExpandBounce $time * 4 ease-in-out infinite;
        transition: all $time;

        &::before,
        &::after {
            border-bottom: 11px solid $c-light;
            box-sizing: content-box;
            content: '';
            display: block;
            height: 9px;
            left: 14px;
            position: absolute;
            top: 10px;
            transition: all $time linear;
            width: 2px;
        }

        &::before {
            transform: rotate(-135deg);
        }

        &::after {
            transform: rotate(135deg);
        }

        &.__active {
            animation: none;
            transform: rotate(180deg);
        }
    }

    &-collapsed {
        //padding: 30px 0;

    }
}

@keyframes infoExpandBounce {
    0% {
        top: 10px;
    }
    50% {
        top: 20px;
    }
    100% {
        top: 10px;
    }
}