form {
    input,
    textarea {
        border-radius: 25px;
        border: 1px solid $c-grey;
        color: $c-accent;
        font-size: 14px;
        font-weight: bold;
        outline: none;
        padding: 0 20px;

        @include md {
            font-size: 20px;
            font-weight: normal;
            text-align: left;
        }

        ::placeholder {
            color: $c-lgrey;
        }

        &.validation-item.error {
            border-color: $c-error;
            transition: all $time/2;
        }
    }

    input {
        line-height: 38px;
        text-align: center;

        @include md {
            line-height: 48px;
        }
    }

    textarea {
        line-height: 1.4;
        padding-top: 15px;
        resize: none;
    }
}

.form {
    &-item {
        margin-bottom: 20px;

        @include lg {
            margin-bottom: 25px;
        }

        &-title {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            padding-left: 20px;
            width: 100%;
        }

        &.validation-item.error {

            .form-item-title {
                color: $c-error;
                transition: all $time/2;
            }

            input,
            textarea,
            .form-item-file label,
            .form-item-file-uploaded {
                border-color: $c-error;
                transition: all $time/2;
            }
        }

        &-file {
            cursor: pointer;

            input {
                display: none;
            }

            &-uploaded,
            label {
                background: $c-lgrey-5;
                border-radius: 25px;
                border: 1px dashed $c-lgrey-4;
                color: $c-accent;
                font-size: 14px;
                font-weight: bold;
                line-height: 38px;
                margin-bottom: 0;
                outline: none;
                padding: 0 20px;
                text-align: center;
                width: 100%;

                @include md {
                    font-size: 16px;
                    line-height: 48px;
                }

                span {
                    color: $c-dark-grey;
                    font-weight: normal;
                }
            }

            label {
                cursor: pointer;
            }

            &-uploaded {
                display: none;
                position: relative;
            }

            &-delete {
                border: 0;
                height: 20px;
                width: 20px;
                background-color: $c-none;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../img/svg/close.svg);
            }
        }
    }

    &-select {
        position: relative;

        &-link {
            border-radius: 30px;
            border: 1px solid $c-accent;
            color: $c-accent;
            display: block;
            font-size: 20px;
            font-weight: bold;
            line-height: 38px;
            outline: none;
            overflow: hidden;
            padding: 0 35px 0 50px;
            position: relative;
            text-align: left;
            text-decoration: none;
            transition: $time ease-in-out;
            white-space: nowrap;
            width: 100%;

            @include md {
                font-size: 20px;
                font-weight: normal;
                line-height: 48px;
            }

            &:hover {
                text-decoration: none;
            }

            &:focus {
                box-shadow: 0px 0px 0px 1px $c-accent;
                text-decoration: none;
            }

            &::after {
                content: '';
                border: solid $c-default;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 3px;
                transform: translateY(-50%) rotate(45deg);
                position: absolute;
                right: 26px;
                top: 50%;
                transition: transform $time/2 ease-in-out;
            }

            &.__active {
                color: $c-default;
                font-weight: bold;
            }

            &.__icon {
                &::before {
                    content: '';
                    display: block;
                    height: 20px;
                    width: 20px;
                    position: absolute;
                    left: 18px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
                
                &.__location {
                    &::before {
                        background-image: url(../img/svg/location.svg);
                    }
                }

                &.__calendar {
                    &::before {
                        background-image: url(../img/svg/calendar.svg);
                    }
                }

                &.__training-type {
                    &::before {
                        background-image: url(../img/svg/training-type.svg);
                    }
                }
            }
        }

        &-content {
            background-color: $c-light;
            box-shadow: 0px 2px 10px rgba($c-default, .25);
            //box-sizing: content-box;
            border-radius: 5px;
            display: block;
            left: 0;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            //padding: 20px 0;
            position: absolute !important;
            top: 45px;
            transition: opacity $time ease-in-out;
            visibility: hidden;
            width: 100%;
            z-index: 2;

            @include lg {
                top: 55px;
            }

            .scroll-content {
                padding: 10px 0;
            }
        }

        &.__open {
            .form-select-content {
                max-height: 340px;
                opacity: 1;
                overflow-y: auto;
                visibility: visible;
            }

            .form-select-link {
                &::after {
                    transform: translateY(-50%) rotate(-135deg);
                }
            }
        }

        &-item {
            color: $c-default;
            display: block;
            font-size: 20px;
            padding: 4px 28px;
            //text-decoration: none;
            transition: $time linear;

            &.__current {
                color: $c-accent;
                font-weight: bold;
            }

            &.__disabled {
                //color: $c-dgrey;
            }

            &:hover {
                background-color: $c-lgrey;
                text-decoration: none;
            }
        }
    }

    &-checkbox {
        display: none;

        &.validation-item.error {
            + .form-checkbox-label:before {
                background: rgba($c-error, .15);
                border-color: $c-error;
                border-width: 2px;
                transition: all $time/2;
            }
        }

        &-item {
            padding-left: 20px;
        }

        &-container {

        }

        &-label {
            display: inline-block;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;
            position: relative;
            padding-left: 30px;
            transition: .2s linear;
            vertical-align: middle;

            &::before {
                content: '';
                //background-color: $c-lgrey-2;
                background-color: $c-none;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 10px 8px;
                border: 1px solid $c-grey;
                border-radius: 2px;
                left: 0;
                position: absolute;
                top: 5px;
                transition: all $time/2 linear;
                width: 16px;
                height: 16px;
            }
        }

        &:checked +.form-checkbox-label:before {
            background-color: $c-accent;
            background-image: url(../img/svg/check.svg);
            border: 2px solid $c-accent;
        }
    }

    &-radio {
        display: none;

        &-label {
            cursor: pointer;
            display: inline-block;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 0;
            padding-left: 30px;
            position: relative;
            transition: .2s linear;
            vertical-align: middle;

            &::before {
                background-color: transparent;
                border-radius: 100%;
                border: 2px solid $c-grey;
                content: '';
                height: 19px;
                left: 0;
                position: absolute;
                top: 4px;
                transition: all $time/2 linear;
                width: 19px;
            }
        }

        &:checked +.form-radio-label {
            font-weight: bold;

            &::before {
                border: 6px solid $c-accent;
            }
        }
    }
}