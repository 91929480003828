.page-404 {
    min-height: 100vh;

    section {
        background: $c-404;
        padding: 80px 0;
        text-align: center;

        @include lg {
            padding-top: 168px;
            padding-bottom: 110px;
        }
    }

    &-image {
        margin-bottom: 20px;
    }

    h1 {
        margin-bottom: 50px;
        text-align: center;

        @include lg {
            font-size: 43px;
            line-height: 56px;
            margin-bottom: 80px;
        }
    }

    &-link {
        padding-left: 70px;
        padding-right: 70px;
    }
}