.header {
    &-container {
        align-items: center;
        display: flex;
        margin: 0 auto;
        max-width: 540px;
        position: relative;
        padding: 14px 5px 14px 15px;

        @include md {
            max-width: 720px;
        }

        @include lg {
            max-width: calc(100% - 2%);
            padding: 25px 0;
        }

        @include xl {
            max-width: 1240px;
        }
    }
    
    a {
        @include hover-focus-active {
            text-decoration: none;
        }
    }

    &-logo {
        flex: 0 0 50%;
        max-width: 50%;

        @include lg {
            flex: 0 0 12%;
            max-width: 12%;
        }

        @include xl {
            flex: 0 0 16.66667%;
            max-width: 16.66667%;
        }

        a {
            display: inline-block;
        }

        img {
            max-width: 98px;
        }
    }

    &-nav {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        flex: 0 0 50%;
        max-width: 50%;

        @include lg {
            flex: 0 0 88%;
            max-width: 88%;
            justify-content: space-between;
            position: relative;
        }

        @include xl {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
        }

        &-menu {
            @include media-breakpoint-down(md) {
                display: none;
                padding: 30px 45px;

                position: absolute;
                top: 80px;
                left: 0;
                width: 100%;
                background: $c-lgrey-5;
                z-index: 2;

                &::before {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 0;
                    background: $c-accent;
                    transition: width $time;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            @include lg {
                display: flex;
            }

            &.__open {
                &::before {
                    width: 100%;
                    transition: width $time;
                }
            }

            &.__submenu-opened {
                @include media-breakpoint-down(md) {
                    padding-left: 58px;

                    .menu-item:not(.__open),
                    .header-nav-menu-bottom {
                        display: none;
                    }
                }
            }
            
            a:hover {
                //text-decoration: none;
            }

            .menu {
                margin-bottom: 0;
                padding-left: 0;
                list-style: none;

                @include lg {
                    display: flex;
                }

                &-item {
                    position: relative;
                    transition: all $time;

                    @include lg {
                        border-radius: 20px;

                        line-height: 26px;
                    }



                    &:nth-child(n + 2) {
                        margin-top: 20px;

                        @include lg {
                            margin-left: 10px;
                            margin-top: 0;
                        }

                        @include media(1100) {
                            margin-left: 15px;
                        }

                        //@include xl {
                        //    margin-left: 35px;
                        //}
                    }

                    > a {
                        color: $c-default;
                        font-size: 20px;
                        font-weight: bold;
                        //text-decoration: none;

                        @include hover-focus-active {
                            //text-decoration: none;
                        }

                        @include lg {
                            font-size: 18px;
                            padding: 0 8px;
                        }

                        @include xl {
                            padding: 0 15px;
                        }
                    }

                    &:hover {
                        @include lg {
                            background: $c-accent-light;

                            > a {
                                color: $c-light;

                                &::after {
                                    border-color: $c-light;
                                }
                            }
                        }
                    }

                    &.__dropdown {
                        &.__open {
                            margin-top: 0;

                            @include lg {
                                background: $c-accent;
                            }

                            .sub-menu {
                                display: block;
                            }

                            > a {
                                color: $c-default;
                                //text-decoration: none;

                                @include hover-focus-active {
                                    //text-decoration: none;
                                }

                                @include lg {
                                    color: $c-light;
                                }

                                &::after {
                                    content: '';
                                    //border: solid $c-light;
                                    border-width: 0 2px 2px 0;
                                    display: inline-block;
                                    padding: 3px;
                                    transform: translateY(-50%) rotate(-225deg);
                                    position: absolute;
                                    right: auto;
                                    left: -15px;
                                    top: 50%;

                                    @include lg {
                                        border-color: $c-light;
                                        left: auto;
                                        top: 50%;
                                        transform: rotate(-135deg);
                                        right: 15px;
                                    }
                                }
                            }
                        }

                        &:hover {

                            @include lg {
                                > a {
                                    color: $c-light;

                                    &::after {
                                        border-color: $c-light;
                                        right: 15px;
                                    }
                                }
                            }
                        }

                        > a {
                            cursor: pointer;
                            color: $c-default;
                            position: relative;
                            padding-right: 30px;
                            //text-decoration: none;

                            @include hover-focus-active {
                                //text-decoration: none;

                                @include lg {
                                    //color: $c-light;
                                }
                            }

                            &::after {
                                content: '';
                                border: solid black;
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                padding: 3px;
                                transform: translateY(-50%) rotate(-45deg);
                                position: absolute;
                                right: 15px;
                                top: 50%;
                                transition: all $time/2;

                                @include lg {
                                    top: calc(50% - 5px);
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                }
            }

            &-bottom {
                margin-top: 15px;
                display: flex;
                align-items: center;
                margin-left: -15px;

                @include lg {
                    display: none;
                }

                .__email {
                    height: 25px;
                    width: 25px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    padding: 15px;
                    cursor: pointer;
                    background-image: url(../img/svg/email.svg);
                    display: inline-block;
                    margin-right: 85px;
                    box-sizing: content-box;
                }

                .header-nav-lang  {
                    position: relative;

                    .sub-menu {
                        position: absolute;
                        right: 0;
                        bottom: 100%;
                        display: none;
                    }
                }
            }
        }

        .sub-menu {
            list-style: none;
            padding-left: 0;

            display: none;

            @include lg {
                position: absolute;
                background: $c-light;
                border: 5px;
                padding: 25px;

                box-shadow: 0px 2px 10px rgba($c-default, .25);
                border-radius: 5px;

                top: calc(100% + 30px);
                left: 50%;

                transform: translateX(-50%);
                z-index: 2;
            }

            &-item {
                margin-top: 20px;

                @include lg {
                    margin-top: 0;
                    padding-bottom: 15px;

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                a {
                    color: $c-default;
                    font-size: 18px;
                    //text-decoration: none;

                    @include hover-focus-active {
                        color: $c-accent;
                        //text-decoration: none;
                    }

                    @include lg {
                        font-size: 20px;
                        white-space: nowrap;
                    }
                }
            }
        }

        &-right {
            align-items: center;
            display: flex;

            .header-nav-lang {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }



        &-icons {
            display: flex;

            button {
                background-color: $c-none;
                border: 0;
            }

            &-item {
                height: 26px;
                width: 26px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 16px;
                padding: 0;
                cursor: pointer;
                box-sizing: content-box;
                text-align: center;
                border-radius: 50%;

                @include media-breakpoint-down(md) {
                    height: 40px;
                    width: 40px;
                    box-sizing: content-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:hover {
                    background-color: $c-accent-light;
                }

                &.__phones {
                    svg {
                        fill: $c-default;
                    }

                    &:hover {
                        svg {
                            fill: $c-light;
                        }
                    }
                }

                &.__email {
                    display: none;

                    @include lg {
                        display: inline-block;
                    }

                    &:hover {
                        svg {
                            stroke: $c-light;
                        }
                    }

                    svg {
                        stroke: $c-default;
                    }
                }

                &.__search {
                    &:hover {
                        svg {
                            stroke: $c-light;
                        }
                    }

                    svg {
                        stroke: $c-default;
                    }
                }

                &:nth-child(n + 2) {
                    margin-left: 15px;

                    @include xl {
                        margin-left: 25px;
                    }
                }

                &.__dropdown {
                    position: relative;

                    &.__open {
                        background: $c-accent;
                        margin-top: 0;

                        &.__phones {
                            svg {
                                fill: $c-light;
                            }
                        }

                        .sub-menu {
                            display: block;

                            @include media-breakpoint-down(md) {
                                background: $c-light;
                                border-radius: 5px;
                                border: 5px;
                                box-shadow: 0 2px 10px rgba(0,0,0,.25);
                                left: 50%;
                                padding: 25px;
                                position: absolute;
                                top: calc(100% + 30px);
                                transform: translateX(-50%);
                                width: 240px;
                                z-index: 1;
                            }
                        }
                    }

                    > a {
                        position: relative;
                        display: flex;
                        width: 100%;
                        height: 100%;
                        align-items: center;
                        justify-content: center;
                    }

                    .sub-menu {
                        &::before {
                            content: '';
                            width: 0;
                            height: 0;
                            //border-left: 12px solid transparent;
                            //border-right: 12px solid transparent;
                            //
                            //border-right: 15px solid #000;
                            //border-bottom: 15px solid #000;
                            //border-top: 15px solid $c-light;

                            border: 10px solid black;
                            border-color: transparent transparent $c-light $c-light;

                            position: absolute;
                            top: 8px;
                            left: calc(50% + 5px);
                            transform: rotate(135deg) translateX(-50%);

                            transform-origin: 0 0;

                            box-shadow: -3px 2px 3px 0 rgba($c-default, 0.15);

                            //box-shadow: 0px 2px 10px rgba($c-default, .25);
                        }
                    }
                }
            }
        }

        &-lang {
            margin-left: 10px;

            @include xl {
                margin-left: 30px;
            }

            a {
                display: inline-block;
                line-height: 26px;
                padding-right: 36px;
                position: relative;
                //text-decoration: none;
                color: $c-default;

                &::after {
                    content: '';
                    display: block;
                    height: 26px;
                    width: 26px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-repeat: no-repeat;
                    background-size: contain;
                }

                &:hover {
                    //text-decoration: none;
                }

                &.__active {
                    border-radius: 20px;
                    font-weight: bold;
                    padding-left: 15px;

                    &:hover {
                        background: $c-accent-light;
                        color: $c-light;

                    }
                }
                
                &.__uk {
                    &::after {
                        background-image: url(../img/icons/ukraine.png);
                    }
                }

                &.__en {
                    &::after {
                        background-image: url(../img/icons/britain.png);
                    }
                }

                &.__ru {
                    &::after {
                        background-image: url(../img/icons/russia.png);
                    }
                }


            }

            &.__dropdown {
                position: relative;

                &.__open {

                    .__active {
                        background: $c-accent;
                        color: $c-light;
                    }

                    .sub-menu {
                        display: block;
                    }
                }

                > a {
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                }

                .sub-menu {
                    &::before {
                        content: '';
                        width: 0;
                        height: 0;
                        border: 10px solid black;
                        border-color: transparent transparent $c-light $c-light;
                        position: absolute;
                        top: 8px;
                        left: calc(50% + 5px);
                        transform: rotate(135deg) translateX(-50%);
                        transform-origin: 0 0;
                        box-shadow: -3px 2px 3px 0 rgba($c-default, .15);
                    }
                }
            }

            &.__popup {
                
                .sub-menu {
                    background: $c-lgrey-5;

                    &-item a {
                        padding-left: 15px;
                    }
                }

            }



        }
    }

    .burger {
        background: $c-none;
        border: 0;
        position: relative;
        height: 30px;
        width: 30px;
        padding: 5px;
        flex-shrink: 0;
        box-sizing: content-box;
        cursor: pointer;
        margin-left: 15px;
        transition: all $time ease-in-out;

        &::before,
        &::after {
            content: '';
            display: inline-block;
            height: 3px;
            border-radius: 3px;
            width: 20px;
            background: $c-default;
            position: absolute;
            left: calc(50% - 10px);
            transition: all $time/2 ease-in-out;
        }

        &::before {
            top: calc(50% - 5px);
        }

        &::after {
            top: calc(50% + 2px);
        }

        &.__open {
            &::before {
                top: calc(50% - 2px);
                transform: rotate(45deg);
            }

            &::after {
                top: calc(50% - 2px);
                transform: rotate(-45deg);
            }
        }

        @include lg {
            display: none;
        }
    }
}