// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal;
    display: none;
    //overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;

    &-open {
        overflow: hidden;

        //& {
        //    overflow-x: hidden;
        //    overflow-y: auto;
        //}
    }

    &-dialog {
        position: relative;
        width: auto;
        margin: $modal-dialog-margin;
        pointer-events: none;

        .modal.fade & {
            @include transition($modal-transition);
            transform: translate(0, -25%);
        }
        .modal.show & {
            transform: translate(0, 0);
        }

        &-centered {
            display: flex;
            align-items: center;
            min-height: calc(100% - (#{$modal-dialog-margin} * 2));
        }
    }

    // Actual modal
    &-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
        // counteract the pointer-events: none; in the .modal-dialog
        pointer-events: auto;
        background-color: $modal-content-bg;
        background-clip: padding-box;
        border: $modal-content-border-width solid $modal-content-border-color;
        @include border-radius($border-radius-lg);
        @include box-shadow($modal-content-box-shadow-xs);
        // Remove focus outline from opened modal
        outline: 0;
    }

    // Modal background
    &-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $zindex-modal-backdrop;
        background-color: $modal-backdrop-bg;

        // Fade for backdrop
        &.fade { opacity: 0; }
        &.show { opacity: $modal-backdrop-opacity; }
    }

    // Modal header
    // Top section of the modal w/ title and dismiss
    &-header {
        background: $c-none;
        display: flex;
        align-items: flex-start; // so the close btn always stays on the upper right corner
        justify-content: center; // Put modal header elements (title and dismiss) on opposite ends
        //padding: $modal-header-padding;
        //border-bottom: $modal-header-border-width solid $modal-header-border-color;
        @include border-top-radius($border-radius-lg);

        .close {
            background: $c-none;
            height: 47px;
            width: 47px;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
            transition: all $time;

            &:hover {
                opacity: .8;
            }

            span {
                display: inline-block;
                height: 2px;
                width: 20px;
                background: $c-default;
                position: absolute;
                top: 50%;
                left: 50%;

                &:first-child {
                    transform: translateX(-50%) rotate(45deg);
                }

                &:last-child {
                    transform: translateX(-50%) rotate(-45deg);
                }
            }
        }
    }

    // Title text within header
    &-title {
        margin-bottom: 0;
        line-height: $modal-title-line-height;
    }

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    &-body {
        position: relative;
        // Enable `flex-grow: 1` so that the body take up as much space as possible
        // when should there be a fixed height on `.modal-dialog`.
        flex: 1 1 auto;
        //padding: $modal-inner-padding;
    }

    // Footer (for actions)
    &-footer {
        display: flex;
        align-items: center; // vertically center
        justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
        padding: $modal-inner-padding;
        border-top: $modal-footer-border-width solid $modal-footer-border-color;

        // Easily place margin between footer elements
        > :not(:first-child) { margin-left: .25rem; }
        > :not(:last-child) { margin-right: .25rem; }
    }

    // Measure scrollbar width for padding body during modal show/hide
    &-scrollbar-measure {
        position: absolute;
        top: -9999px;
        width: 50px;
        height: 50px;
        overflow: scroll;
    }

    // Scale up the modal
    @include media-breakpoint-up(sm) {
        // Automatically set modal's width for larger viewports
        .modal {

            &-dialog {
                max-width: $modal-md;
                margin: $modal-dialog-margin-y-sm-up auto;

                &-centered {
                    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
                }
            }

            &-content {
                @include box-shadow($modal-content-box-shadow-sm-up);
            }

            &-sm {
                max-width: $modal-sm;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .modal-lg { max-width: $modal-lg; }
    }

    // Modal Search
    &-search {
        .modal-dialog {
            max-width: 100%;

            @include sm {
                max-width: 90%;
            }

            @include md {
                max-width: 80%;
            }

            @include xl {
                max-width: 1240px;
            }
        }

        .modal-content {
            background: $c-none;
            border: 0;

            .modal-header {
                margin-bottom: 57px;

                @include lg {
                    margin-bottom: 48px;
                }
            }
        }

        .close {
            background: $c-none;
            height: 47px;
            width: 47px;
            border: 2px solid $c-light;
            border-radius: 50%;
            position: relative;
            transition: all $time;

            &:hover {
                opacity: .8;
            }

            span {
                display: inline-block;
                height: 2px;
                width: 26px;
                background: $c-light;
                position: absolute;
                top: 50%;
                left: 50%;

                &:first-child {
                    transform: translateX(-50%) rotate(45deg);
                }

                &:last-child {
                    transform: translateX(-50%) rotate(-45deg);
                }
            }
        }

        &-form {
            position: relative;
            width: 100%;

            input {
                background: $c-light;
                border-radius: 5px;
                height: 60px;
                margin-bottom: 37px;
                width: 100%;

                @include lg {

                    height: 78px;
                    margin-bottom: 0;
                    padding-right: 120px;
                }
            }

            button {
                height: 40px;
                line-height: 40px;
                width: 100%;

                @include lg {
                    position: absolute;
                    right: 17px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: auto;
                }
            }
        }
    }

    // Modal Training
    &-training {
        .modal-dialog {
            max-width: 992px;
        }

        .modal-content {
            padding: 50px 25px;

            @include lg {
                padding: 50px 80px;
            }
        }

        .modal-header {
            margin-bottom: 30px;

            @include lg {
                margin-bottom: 30px;
            }
        }

        .modal-title {
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            text-transform: uppercase;

            @include lg {
                font-size: 30px;
                line-height: 39px;
            }
        }

        &-name {
            font-weight: bold;
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 40px;
            text-align: center;

            @include lg {
                font-size: 22px;
            }
        }

        &-icons {
            font-size: 22px;
            line-height: 28px;
            display: flex;
            margin-bottom: 35px;
            flex-direction: column;
            align-items: center;

            @include lg {
                align-items: center;
                flex-direction: row;
                justify-content: center;
                margin-bottom: 55px;
            }

            &-item {
                align-items: center;
                display: flex;

                @include lg {
                    margin-right: 75px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                img {
                    margin-right: 18px;
                }
            }
        }

        &-form {

            &-inputs {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 30px;

                @include lg {
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row;
                }

                input {
                    border-color: $c-grey;
                    font-weight: normal;
                    width: 100%;
                    margin-bottom: 15px;
                    max-width: 320px;

                    @include lg {
                        width: 265px;
                    }
                }
            }

            &-captcha {
                margin: 0 auto 24px;
                text-align: center;

                #apply_seminar--recaptcha > div {
                    margin: 0 auto;
                }
            }

            &-oferta {
                margin-bottom: 45px;
                text-align: center;
                
                a {
                    color: $c-accent;
                    text-decoration: underline;

                    @include hover-focus-active {
                        text-decoration: none;
                    }
                }
            }

            &-buttons {
                align-items: center;
                display: flex;
                flex-direction: column;

                @include lg {
                    align-items: center;
                    flex-direction: row;
                    justify-content: center;
                }

                button {
                    margin-bottom: 15px;
                    max-width: 320px;
                    padding: 0 10px;
                    width: 100%;

                    @include lg {
                        margin-right: 20px;
                        margin-bottom: 0;
                        width: 190px;
                    }

                    &:last-child {
                        margin-right: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    // Filters Mobile
    &.filters-mobile {
        .modal-dialog {
            margin: 0;
            max-width: none;
            width: 100%;
        }

        .modal-content {
            padding-bottom: 50px;
        }

        .filters-mobile-form {
            &-title {
                font-weight: bold;
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 10px;
                padding: 0 20px;
            }

            .filters-block {
                &-name {
                    background: $c-lgrey-2;
                    height: 52px;
                    padding: 0 20px;
                    margin-bottom: 0;

                    font-weight: bold;
                    font-size: 18px;
                    line-height: 52px;

                    color: $c-dark-grey;
                }

                &-container {
                    padding: 20px;

                    .filters-item {
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .form-checkbox {

                            &:checked +.form-checkbox-label {
                                font-weight: bold;

                                &::before {
                                    background-color: $c-accent;
                                }
                            }

                            &-label {
                                font-size: 20px;
                                line-height: 1;
                                margin-bottom: 0;

                                &::before {
                                    background-color: $c-light;
                                    border-color: $c-accent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}