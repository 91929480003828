.blog {

    &.__preview .blog-card {
        margin-bottom: 0;

        @include media-max(576) {
            &:nth-of-type(n + 2) {
                display: none;
            }
        }

        @include media-max(768) {
            &:nth-of-type(n + 3) {
                display: none;
            }
        }
    }

    .page-title {
        text-align: center;
    }

    &-container {
        @include md {
            position: relative;
        }
    }

    &-tabs {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-around;

        @include md {
            justify-content: flex-start;
            padding-left: 20px;
        }

        &-item {
            color: $c-default;
            font-size: 18px;
            font-weight: bold;
            display: block;
            position: relative;
            padding-bottom: 5px;
            //text-decoration: none;

            @include md {
                margin-right: 50px;
            }

            &::after {
                background: $c-accent;
                bottom: 0;
                content: '';
                display: block;
                height: 2px;
                left: 0;
                position: absolute;
                transform: scaleX(0);
                transition: all $time;
                visibility: hidden;
                width: 100%;
            }

            &.active {
                color: $c-accent;

                &::after {
                    visibility: visible;
                    transform: scaleX(1);
                }
            }

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }
        }

        &-content {
            > .blog-tabs-content-item {
                display: none;
            }
            > .active {
                display: block;
            }
        }
    }

    &-card {
        cursor: pointer;
        margin-bottom: 30px;

        @include lg {
            margin-bottom: 120px;
        }

        &-body {
            border-radius: 15px;
            padding-bottom: 10px;
            transition: all $time/2;

            &:hover {
                box-shadow: 0px 2px 15px rgba($c-default, .25);

                .blog-item-image img {
                    transform: scale(1.1);
                }
            }
        }

        &-image {
            height: 248px;
            margin-bottom: 15px;
            border-radius: 15px;
            overflow: hidden;
            position: relative;

            @include lg {
                margin-bottom: 22px;
                height: 320px;
            }

            @include xl {
                height: 450px;
            }

            img {
                border-radius: 15px;
                height: 100%;
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                transition: all $time;
            }
        }

        &-date {
            color: rgba($c-default, .6);
            font-size: 15px;
            margin-bottom: 8px;
            padding-left: 10px;

            @include lg {
                padding-left: 30px;
            }
        }

        &-title {
            font-size: 20px;
            font-weight: bold;
            line-height: 25px;
            margin-bottom: 5px;
            padding: 0 10px;
            height: 52px;
            overflow: hidden;

            @include lg {
                font-size: 24px;
                height: 52px;
                padding: 0 30px;
            }
        }

        &-text {
            font-size: 14px;
            line-height: 20px;
            padding: 0 10px;

            @include lg {
                font-size: 16px;
                padding: 0 30px;
            }
        }

        &-link {
            font-size: 18px;
            color: $c-accent;
            display: inline-block;
            margin-bottom: 13px;
            padding: 0 10px;
            //text-decoration: none;

            @include lg {
                padding: 0 30px;
            }
            
            @include hover-focus-active {
                text-decoration: underline;
            }
        }

        &-hash_tags {
            border-top: 1px solid $c-lgrey-4;
            font-size: 14px;
            padding: 18px 10px 0;

            @include lg {
                padding-left: 30px;
                padding-right: 30px;
            }

            a {
                color: #474774;
                margin-right: 9px;
                //text-decoration: none;
                //white-space: nowrap;
                
                @include hover-focus-active {
                    text-decoration: underline;
                }
            }
        }
    }

    &-link_posts {
        margin-top: 45px;
        text-align: center;

        @include md {
            margin-top: 0;
            position: absolute;
            right: 0;
            top: -3px;
        }

        a {
            @include md {
                height: 35px;
                line-height: 35px;
            }

            @include lg {
                height: 40px;
                line-height: 40px;
            }
        }
    }

    &-post {

        &-section {
            //padding-bottom: 0;

            @include lg {
                padding-top: 50px;
            }
        }
        
        &-title {
            font-weight: bold;
            font-size: 25px;
            line-height: 28px;
            text-align: left;
            
            @include lg {
                font-weight: bold;
                font-size: 50px;
                line-height: 60px;
                margin-bottom: 15px;
            }
        }

        &-date {
            font-size: 14px;
            line-height: 1.6;
            margin-bottom: 0;
            text-transform: lowercase;
            opacity: .6;

            @include lg {
                font-size: 16px;
            }
        }

        .social-share {
            @include media-breakpoint-down(md) {
                margin: 10px auto 30px;
            }
        }
    }
}